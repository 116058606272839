import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { theme } from "../../Layouts/Themesetup/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const EditAcademicFees = ({
  setIsEdit,
  editAcademicFee,
  updateAcademicfees,
  classNameState,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setIsEdit(false);
  };

  const formik = useFormik({
    initialValues: {
      className: "",
      bookFees: 0,
      term1: 0,
      term2: 0,
      term3: 0,
      labFees: 0,
      year: "",
    },
    validationSchema: Yup.object({
      className: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      updateAcademicfees(values);
      handleClose();
    },
  });

  useEffect(() => {
    formik.setFieldValue("className", editAcademicFee?.className);
    formik.setFieldValue("bookFees", editAcademicFee?.bookFees);
    formik.setFieldValue("term1", editAcademicFee?.term1);
    formik.setFieldValue("term2", editAcademicFee?.term2);
    formik.setFieldValue("term3", editAcademicFee?.term3);
    formik.setFieldValue("labFees", editAcademicFee?.labFees);
    formik.setFieldValue("year", editAcademicFee?.year);
    setOpen(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 2, width: "25ch" },
              }}
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    sx={{ margin: "15px", marginBottom: "0", width: "100%" }}
                  >
                    <InputLabel size="large" id="demo-simple-select-label">
                      Class Name
                    </InputLabel>
                    <Select
                      size="large"
                      labelId="demo-simple-select-label"
                      id="className"
                      value={formik.values.className}
                      label="Class Name"
                      name="className"
                      onChange={formik.handleChange}
                    >
                      {classNameState?.data.map((ye, key) => {
                        return (
                          <MenuItem key={key} value={ye.className}>
                            {ye.className}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "15px",
                        marginBottom: 0,
                        width: "100%",
                      },
                    }}
                    name="bookFees"
                    label="Book Fees"
                    type="number"
                    value={formik.values.bookFees}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.bookFees && formik.errors.bookFees ? (
                    <div style={{ color: "red" }}>{formik.errors.bookFees}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "15px",
                        marginBottom: 0,
                        width: "100%",
                      },
                    }}
                    name="term1"
                    label="Term 1"
                    type="number"
                    value={formik.values.term1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.term1 && formik.errors.term1 ? (
                    <div style={{ color: "red" }}>{formik.errors.term1}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "15px",
                        marginBottom: 0,
                        width: "100%",
                      },
                    }}
                    name="term2"
                    label="Term 2"
                    type="number"
                    value={formik.values.term2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.term2 && formik.errors.term2 ? (
                    <div style={{ color: "red" }}>{formik.errors.term2}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "15px",
                        marginBottom: 0,
                        width: "100%",
                      },
                    }}
                    name="term3"
                    label="Term 3"
                    type="number"
                    value={formik.values.term3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.term3 && formik.errors.term3 ? (
                    <div style={{ color: "red" }}>{formik.errors.term3}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "15px",
                        marginBottom: 0,
                        width: "100%",
                      },
                    }}
                    name="labFees"
                    label="Lab Fees"
                    type="number"
                    value={formik.values.labFees}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.labFees && formik.errors.labFees ? (
                    <div style={{ color: "red" }}>{formik.errors.labFees}</div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "15px",
                        marginBottom: 0,
                        width: "100%",
                      },
                    }}
                    type="number"
                    name="totalFees"
                    label="Total Fees"
                    value={
                      parseInt(formik.values.bookFees) +
                      parseInt(formik.values.term1) +
                      parseInt(formik.values.term2) +
                      parseInt(formik.values.term3) +
                      parseInt(formik.values.labFees)
                    }
                    id="outlined-read-only-input"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Button
                  variant="contained"
                  disabled={!formik.values.className && "disabled"}
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default EditAcademicFees;
