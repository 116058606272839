import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

const ITEM_HEIGHT = 100;

const TableHeaderCustomize = ({ options, handleTableHeaders }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleTableHeaders(options);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "30ch",
          },
        }}
      >
        {options
          .filter((option) => !option?.isDefault)
          .map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.show = !option.show;
              }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked={option.show} />}
                label={option.label}
              />
            </MenuItem>
          ))}
        <Button variant="contained" onClick={handleClose}>
          Done
        </Button>
      </Menu>
    </>
  );
};

export default TableHeaderCustomize;
