import { FileUpload } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Table3 from "../../Components/Table/Table3";
import AddDiscount from "./Adddiscount";
import EditDiscount from "./EditDiscount.js";

const columns = [
  {
    id: "discountName",
    align: "center",
    label: "Discount Name",
    minWidth: 100,
  },
  { id: "busFees", align: "center", label: "Bus Fees", minWidth: 100 },
  { id: "tuitionFees", align: "center", label: "Tution Fees", minWidth: 100 },
  { id: "bookFees", align: "center", label: "Book Fees", minWidth: 100 },
  { id: "labFees", align: "center", label: "Lab Fees", minWidth: 100 },

  {
    id: "discountPercentage",
    align: "center",
    label: "Discount Percentage",
    minWidth: 100,
  },
];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const Discount = () => {
  const [discountList, setDiscount] = useFetch({ data: [] });
  const [exportFile, setExportFile] = useFetch(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editDiscount, setEditDiscount] = useState(null);
  const openDiscountEdit = (discount) => {
    setIsEdit(true);
    setEditDiscount(discount);
  };
  const fileExportRef = useRef(null);

  const getDiscount = () => {
    setDiscount({
      api: () => api.fetchDiscount(),
      res: (data) => ({ data: data.data.discountTypeResponseList }),
    });
  };

  const postDiscount = (val) => {
    setDiscount({
      api: () => api.createDiscount(val),
      res: (data) => ({ data: [...discountList.data, data.data] }),
    });
  };

  const updateDiscount = (val) => {
    setDiscount({
      api: () => api.updateDiscount(val),
      res: (data) => {
        const finalDiscountList = discountList.data.map((discount) => {
          if (discount.uniqueId === val.uniqueId) return data.data;

          return discount;
        });
        return { data: finalDiscountList };
      },
    });
  };

  const deleteDiscount = (val) => {
    setDiscount({
      api: () => api.deleteDiscount(val.uniqueId),
      res: (data) => {
        const finalDiscountList = discountList.data.filter(
          (discount) => discount.uniqueId !== val.uniqueId
        );
        return { data: finalDiscountList };
      },
    });
  };

  const handleExportFile = () => {
    setExportFile({
      api: () => api.exportDiscount(),
      res: (data) => ({ fileUuid: data.data }),
    });
  };

  useEffect(() => {
    getDiscount();
  }, []);

  useEffect(() => {
    if (exportFile != null) {
      fileExportRef.current.click();
    }
  }, [exportFile]);

  return (
    <>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Fees Discount
          </Typography>
          <a
            href={`https://svsschool.co.in/services/svs/api/discount/exportFile/${exportFile?.fileUuid}`}
            ref={fileExportRef}
            target="_blank"
            rel="noreferrer"
            style={{ display: "none" }}
          ></a>
          <div style={{display:'flex', gap: 15}}>
          <Button variant="outlined" onClick={handleExportFile} color="primary">
            {<FileUpload />} Export
          </Button>
          <AddDiscount postDiscount={postDiscount} />
          </div>
          {isEdit && (
            <EditDiscount
              setIsEdit={setIsEdit}
              editDiscount={editDiscount}
              updateDiscount={updateDiscount}
            />
          )}
        </Box>

        <Table3
          columns={columns}
          rows={discountList.data}
          visibilityIcon={false}
          openEditModel={openDiscountEdit}
          deleteHandler={deleteDiscount}
        />
      </Tablebox>
    </>
  );
};

export default Discount;
