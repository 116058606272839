import { AUTH, LOGOUT, UNAUTHORIZED } from "../constants/actionTypes";

const login = (
  state = { authData: JSON.parse(localStorage.getItem("login")) },
  action
) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("login", JSON.stringify({ ...action?.payload }));
      return { authData: action?.payload };

    case LOGOUT:
      sessionStorage.clear();
      localStorage.clear();
      return { authData: null };
    case UNAUTHORIZED:
      sessionStorage.clear();
      localStorage.clear();
      return { authData: null };
    default:
      return state;
  }
};

export default login;
