import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Button = styled("button")(({ theme }) => ({
  padding: 10,
  color: theme.primary,
  borderRadius: 8,
  border: 0,
}));

const Table4 = ({ columns, rows }) => {
  const cellStyle = {
    borderRight: "1px solid lightgray",
  };
  const getValue = (column, value) => {
    if (column.format && typeof value === "number") return column.format(value);

    if (typeof value === "string" && !value) return "-";

    if (typeof value === "boolean") return value ? "Yes" : "No";

    return value;
  };
  let count = 1;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 680, paddingBottom: 5 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={1}
              >
                S.No
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={2}
              >
                Class
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={4}
              >
                A: Fees (without any deduction or discount)
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={4}
              >
                B: Discount
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={4}
              >
                C: Final (A - B)
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={4}
              >
                D: Paid
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={4}
              >
                E: Balance (C - D)
              </TableCell>
              <TableCell
                style={{ ...cellStyle, fontWeight: "bold" }}
                align="center"
                colSpan={4}
              >
                D: Total (B, C, D, E)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={cellStyle}>{"  "}</TableCell>

              {columns.map((column) => {
                let tableCellStyle = {
                  minWidth: column.minWidth,
                  fontWeight: "bold",
                };
                if (column.border) {
                  tableCellStyle = { ...tableCellStyle, ...cellStyle };
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={tableCellStyle}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell style={cellStyle}>{count++}</TableCell>
                  {columns.map((column) => {
                    const value = column.calculate
                      ? column.calculate(row)
                      : row[column.id];
                    let tableCellStyle = {};
                    if (column.border) {
                      tableCellStyle = { ...cellStyle };
                    }

                    return (
                      <TableCell
                        style={tableCellStyle}
                        key={column.label}
                        align={column.align}
                      >
                        {getValue(column, value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Table4;
