import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch.js";
import { AUTH } from "../../constants/actionTypes.js";

const Login = () => {
  const unique_id = uuid();

  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
    deviceId: "",
  });

  const [data, logIn] = useFetch(null);
  const [showpassword, setShowpassword] = useState(false);
  const isLoggedIn = useSelector((state) => state.login);

  const signIn = (value) => {
    logIn({
      api: () => api.signin(value),
      dispatchType: (data) => ({ type: AUTH, payload: data }),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(userCredentials);
  };

  const handleClickShowPassword = () => {
    setShowpassword(!showpassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {isLoggedIn?.authData?.status ? (
        <Navigate to="/student" replace={true} />
      ) : (
        <Box
          sx={{
            display: "flex",
            paddingTop: 10,
            "& > :not(style)": {
              m: "auto",
              p: 2,
            },
          }}
        >
          <Paper variant="outlined">
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              SVS School
            </Typography>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", marginTop: 1, marginBottom: 1 }}
            >
              Login
            </Typography>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "35ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <TextField
                    type="text"
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="email"
                    variant="outlined"
                    value={userCredentials.email}
                    onChange={(e) =>
                      setUserCredentials({
                        ...userCredentials,
                        email: e.target.value,
                        deviceId: unique_id,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showpassword ? "text" : "password"}
                      value={userCredentials.password}
                      onChange={(e) =>
                        setUserCredentials({
                          ...userCredentials,
                          password: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showpassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 3 }}>
                  <Button
                    type="submit"
                    sx={{ width: "100%" }}
                    variant="contained"
                    size="large"
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default Login;
