import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import Loader from "./Components/Loader/loader";
import Header from "./Layouts/Header/index.js";
import Bus from "./Pages/Bus/index.js";
import Class from "./Pages/Class/index.js";
import Discount from "./Pages/Discount/index.js";
import Fees from "./Pages/Fees/Index.js";
import Login from "./Pages/Login/login";
import Student from "./Pages/Student/Index.js";
import ViewStudent from "./Pages/Student/viewStudent.js";
import User from "./Pages/User/Index.js";
import Year from "./Pages/Year/index";
import Dashboard from "./Pages/Dashboard/index";
import FeesPaymentHistory from "./Pages/FeesPaymentHistory/Index";

function App() {
  const isLoggedIn = useSelector((state) => state.login);
  const loader = useSelector((state) => state.loader);

  return (
    <div className="App">
      {loader.isLoading && <Loader />}
      <Router>
        {isLoggedIn.authData && <Header />}

        <Routes>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/login" element={<Login />} />
          {isLoggedIn.authData && (
            <>
              <Route path="/paymentHistory" element={<FeesPaymentHistory />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/student" element={<Student />} />
              <Route path="/studentdetail/:uuid" element={<ViewStudent />} />
              <Route path="/academicyear" element={<Year />} />
              <Route path="/busfees" element={<Bus />}></Route>
              <Route path="/class" element={<Class />}></Route>
              <Route path="/discount" element={<Discount />}></Route>
              <Route path="/academic_fees" element={<Fees />}></Route>
              <Route path="/user" element={<User />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/login" replace={true} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
