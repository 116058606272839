import {createTheme} from '@mui/material/styles';


export const theme = createTheme({
    palette: {
      primary: {
        // Black and green play nicely together.
        main: '#1C74F2',
        light: '#e0e0e0',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#898989',
      },
      lightblack: {
        // This is green.A700 as hex.
        main: '#f1f1f1',
      },
      buttoncolor: {
        // This is green.A700 as hex.
        main:'#1C74F2',
        light: '#3d07dc',
      },
    },
  });

