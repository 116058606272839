import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { theme } from "../../Layouts/Themesetup/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const EditYear = ({ batchYear, updateBatchYear, setIsEdit }) => {
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const formik = useFormik({
    initialValues: {
      startBatchYear: "",
      endBatchYear: "",
    },
    validationSchema: Yup.object({
      startBatchYear: Yup.string()
        .min(4, "Min 4 Numbers!")
        .required("Required"),
      endBatchYear: Yup.string().min(4, "Min 4 Numbers!").required("Required"),
    }),
    onSubmit: (values) => {
      const newBatchYear = `${values.startBatchYear}-${values.endBatchYear}`;
      updateBatchYear(batchYear, newBatchYear);
      formik.resetForm();
      handleClose();
    },
  });

  useEffect(() => {
    const years = batchYear.split("-");

    formik.setFieldValue("startBatchYear", years[0]);
    formik.setFieldValue("endBatchYear", years[1]);
    setOpen(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 2, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <TextField
                  required
                  maxlength="4"
                  type="number"
                  id="startBatchYear"
                  name="startBatchYear"
                  label="Start Year"
                  value={formik.values.startBatchYear}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.startBatchYear &&
                formik.errors.startBatchYear ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.startBatchYear}
                  </div>
                ) : null}
                <TextField
                  required
                  maxlength="4"
                  type="number"
                  id="endBatchYear"
                  name="endBatchYear"
                  label="End Year"
                  value={formik.values.endBatchYear}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.startBatchYear &&
                formik.errors.startBatchYear ? (
                  <div style={{ color: "red" }}>
                    {formik.errors.startBatchYear}
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <Button
                  variant="contained"
                  disabled={
                    !formik.values.startBatchYear ||
                    (!formik.values.startBatchYear ? "disabled" : "")
                  }
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default EditYear;
