import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  LOADER_LOADING,
  LODING_COMPLETED,
  UNAUTHORIZED,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

const useFetch = (initialValue) => {
  const [data, setdata] = useState(initialValue);
  const [error, seterror] = useState(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const fetch = ({
    api,
    res = null,
    load = true,
    dispatchType = false,
    showToast = true,
    callOnSuccess = false,
  }) => {
    load && dispatch({ type: LOADER_LOADING });

    api()
      .then((response) => {
        const result = res ? res(response.data) : response.data;
        setdata(result);
        setSuccess(true);
        seterror(null);
        if (dispatchType) dispatch(dispatchType(result));
        if (callOnSuccess) callOnSuccess();
      })
      .catch((error) => {
        setSuccess(false);

        if (error.response.status === 401) {
          dispatch({ type: UNAUTHORIZED });
        }

        if (showToast) {
          if (error.response.data === "")
            toast("Something went wrong", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          else
            toast(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        seterror(error.response.data);
      })
      .finally(() => {
        load && dispatch({ type: LODING_COMPLETED });
      });
  };

  return [data, fetch, success, error];
};

export default useFetch;
