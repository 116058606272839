import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch.js";
import Modal from "../../Components/Modal/index";
import Table2 from "../../Components/Table/Table2";
import AddStudent from "./Addstudent";

import TableHeaderCustomize from "./TableHeaderCustomize";

const studentDetailsHeaders = [
  {
    id: "studentId",
    label: "Student Id",
    exporthead: "STUDENT_ID",
  },
  {
    id: "name",
    label: "Student Name",
    exporthead: "STUDENT_NAME",
    isDefault: true,
  },
  { id: "dob", label: "DOB", exporthead: "STUDENT_DOB", isDefault: true },
  { id: "gender", label: "Gender", exporthead: "GENDER", isDefault: true },
  {
    id: "mobileNumber",
    label: "Mobile Number",
    exporthead: "STUDENT_MOBILE_NUMBER",
  },
  {
    id: "adharNumber",
    label: "Aadhaar Number",
    exporthead: "AADHAAR_NO",
  },
  { id: "email", label: "Email", exporthead: "STUDENT_EMAIL" },

  {
    id: "previousUnregisteredYearBalance",
    label: "Balance till 2021",
    exporthead: "PREVIOUS_BALANCE",
  },
  { id: "studentCaste", label: "Student Caste", exporthead: "STUDENT_CASTE" },
  {
    id: "studentReligion",
    label: "Student Religion",
    exporthead: "STUDENT_RELIGION",
  },
  {
    id: "studentSubCaste",
    label: "Student Sub Caste",
    exporthead: "STUDENT_SUB_CASTE",
  },
  { id: "admissionNo", label: "Admission No", exporthead: "ADMISSION_NO" },
  {
    id: "admissionDate",
    label: "Admission Date",
    exporthead: "ADMISSION_DATE",
  },
  { id: "emisNo", label: "Emis No", exporthead: "EMIS_NO", isDefault: true },
  { id: "bloodGroup", label: "Blood Group", exporthead: "BLOOD_GROUP" },
  {
    id: "previousSchool",
    label: "Previous School",
    exporthead: "PREVIOUS_SCHOOL",
  },
  { id: "remark", label: "Remark", exporthead: "REMARKS" },
  { id: "tcIssued", label: "TC Issued", exporthead: "TC_ISSUED" },
  {
    id: "feesToBePaid",
    label: "Fees To Be Paid",
    exporthead: "FEES_TO_BE_PAID",
  },
  {
    id: "totalFeesPaid",
    label: "Total Paid Fees",
    exporthead: "TOTAL_FEES_PAID",
  },
  { id: "balance", label: "Balance", exporthead: "BALANCE" },
].map((column) => {
  column.minWidth = 120;
  column.show = false;
  column.all = true;

  return column;
});

const academicDetailsHeaders = [
  { id: "batchYear", label: "Batch", exporthead: "BATCH_YEAR" },
  { id: "routeName", label: "Route Name", exporthead: "ROUTE_NAME" },
  { id: "busNumber", label: "Bus Number", exporthead: "BUS_NUMBER" },
  {
    id: "directorDiscount",
    label: "Director Discount",
    exporthead: "DIRECTOR_DISCOUNT",
  },
  {
    id: "directorBusDiscount",
    label: "Director Bus Discount",
    exporthead: "DIRECTOR_BUS_DISCOUNT",
  },
  {
    id: "discountReason",
    label: "Discount Reason",
    exporthead: "DISCOUNT_REASON",
  },
  { id: "directorName", label: "Director Name", exporthead: "DIRECTOR_NAME" },
  { id: "className", label: "Class Name", exporthead: "CLASS_NAME" },
  { id: "sectionName", label: "Section Name", exporthead: "SECTION_NAME" },
  { id: "groupId", label: "Group ID", exporthead: "GROUP_ID" },
  { id: "discountName", label: "Discount Name", exporthead: "DISCOUNT_TYPE" },
  {
    id: "academicFeesToBePaid",
    label: "Academic Fees To be Paid",
    exporthead: "ACADEMIC_FEES_TO_PAID",
  },
  {
    id: "academicTotalFeesPaid",
    label: "Academic Total Fees Paid",
    exporthead: "ACADEMIC_TOTAL_FEES_PAID",
  },
  {
    id: "academicBalance",
    label: "Academic Balance",
    exporthead: "ACADEMIC_BALANCE",
  },
  {
    id: "academicBusFeesToBePaid",
    label: "Academic Bus Fees To be Paid",
    exporthead: "ACADEMIC_BUS_FEES_TO_PAID",
  },
  {
    id: "academicBusFeesPaid",
    label: "Academic Bus Fees Paid",
    exporthead: "ACADEMIC_BUS_FEES_PAID",
  },
  {
    id: "academicBusFeesBalance",
    label: "Academic Bus Fees Balance",
    exporthead: "ACADEMIC_BUS_FEES_BALANCE",
  },
].map((column) => {
  column.minWidth = 120;
  column.show = false;
  column.all = false;
  return column;
});

const parentDetailsHeaders = [
  { id: "fatherName", label: "Father Name", exporthead: "FATHER_NAME" },
  { id: "motherName", label: "Mother Name", exporthead: "MOTHER_NAME" },
  {
    id: "fatherMobileNo",
    label: "Father Mobile No",
    exporthead: "FATHER_MOBILE",
  },
  {
    id: "motherMobileNo",
    label: "Mother Mobile No",
    exporthead: "MOTHER_MOBILE",
  },
  { id: "homeMobileNo", label: "Home Mobile No", exporthead: "HOME_MOBILE_NO" },
  {
    id: "fatherOccupation",
    label: "Father Occupation",
    exporthead: "FATHER_OCCUPATION",
  },
  {
    id: "motherOccupation",
    label: "Mother Occupation",
    exporthead: "MOTHER_OCCUPATION",
  },
  { id: "fatherCaste", label: "Father Caste", exporthead: "FATHER_CASTE" },
  { id: "motherCaste", label: "Mother Caste", exporthead: "MOTHER_CASTE" },
  {
    id: "fatherReligion",
    label: "Father Religion",
    exporthead: "FATHER_RELIGION",
  },
  {
    id: "motherReligion",
    label: "Mother Religion",
    exporthead: "MOTHER_RELIGION",
  },
  {
    id: "fatherAnnualIncome",
    label: "Father Annual Income",
    exporthead: "FATHER_ANNUAL_INCOME",
  },
  {
    id: "motherAnnualIncome",
    label: "Mother Annual Income",
    exporthead: "MOTHER_ANNUAL_INCOME",
  },
  {
    id: "fatherAadhaar",
    label: "Father Aadhaar",
    exporthead: "FATHER_AADHAAR",
  },
  {
    id: "motherAadhaar",
    label: "Mother Aadhaar",
    exporthead: "MOTHER_AADHAAR",
  },
  {
    id: "fatherQualification",
    label: "Father Qualification",
    exporthead: "FATHER_QUALIFICATION",
  },
  {
    id: "motherQualification",
    label: "Mother Qualification",
    exporthead: "MOTHER_QUALIFICATION",
  },
  { id: "fatherEmail", label: "Father Email", exporthead: "FATHER_EMAIL" },
  { id: "motherEmail", label: "Mother Email", exporthead: "MOTHER_EMAIL" },
  { id: "place", label: "Place", exporthead: "PLACE" },
  { id: "district", label: "District", exporthead: "DISTRICT" },
  { id: "pincode", label: "Pincode", exporthead: "PINCODE" },
  { id: "address1", label: "Address 1", exporthead: "ADDRESS1" },
  { id: "address2", label: "Address 2", exporthead: "ADDRESS2" },
].map((column) => {
  column.minWidth = 120;
  column.show = false;
  column.all = true;

  return column;
});

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 98,
  marginRight: 18,
  borderRadius: 10,
  padding: theme.spacing(2, 2),
  boxShadow: "0px 0px 10px #0000000a",
  background: "#fff",
}));

const Student = () => {
  const [batchYearState, setBatchYearState] = useFetch({ data: [], total: 0 });
  const [classNameState, setClassNameState] = useFetch({ data: [] });
  const [discountState, setDiscountState] = useFetch({ data: [] });
  const [studentList, setStudentList] = useFetch({
    studentResponseList: [],
    totalSize: 0,
  });

  //useState

  // const [pagination, setPagination] = useState({ size: 10, page: 0 });
  const [classIndex, setClassIndex] = useState(null);
  const [tableHeaderState, setTableHeaderState] = useState([
    ...studentDetailsHeaders,
    ...academicDetailsHeaders,
    ...parentDetailsHeaders,
  ]);

  const handleTableHeaders = (headerList) => {
    sessionStorage.setItem("table_header", JSON.stringify(headerList));
    setTableHeaderState(headerList);
  };

  const handleChangePage = (e, val) => {
    formik.setFieldValue("page", val);
    fetchStudent({ ...formik.values, page: val });
  };

  const handleChangeRowsPerPage = (e, val) => {
    formik.setFieldValue("size", e.target.value);
    fetchStudent({ ...formik.values, size: e.target.value });
  };

  const studentView =
    studentList.totalSize === 0
      ? []
      : studentList.studentResponseList?.map((v) => {
          return {
            ...v.parentDetailResponseDto,
            ...v.studentAcademicDetailResponseDto,
            ...v.studentDetailResponseDto,
          };
        });

  const [open, setOpen] = useState(false);
  const [alertval, setAlertval] = useState();
  const [alertv, setAlertv] = useState();

  const formik = useFormik({
    initialValues: {
      batch: "",
      size: 10,
      page: 0,
      paid: "All",
      className: "All",
      sectionName: "All",
      studentName: "",
      discountUuid: "None",
      balance: 0,
      discontinued: 1,
    },
    onSubmit: (values) => {
      // const valuesRequest = {
      //   ...values,
      //   size: pagination.size,
      //   page: pagination.page,
      // };
      const filterValues = {
        classIndex: classIndex,
        values: values,
        size: 10,
        page: 0,
      };
      sessionStorage.setItem("student_filter", JSON.stringify(filterValues));
      fetchStudent({
        ...values,
        discontinued: parseInt(values.discontinued),
        size: 10,
        page: 0,
      });
    },
  });

  const fetchBatchYear = () => {
    setBatchYearState({
      api: () => api.fetchYear({ size: 100, page: 0 }),
      res: (data) => ({
        data: data.data.academicYearDetailsDtoListList,
        total: data.data.total,
      }),
    });
  };

  const fetchClassSection = () => {
    setClassNameState({
      api: () => api.fetchClass(),
      res: (data) => ({ data: data.data.classSectionList }),
    });
  };

  const fetchDiscount = () => {
    setDiscountState({
      api: () => api.fetchDiscount(),
      res: (data) => ({ data: data.data.discountTypeResponseList }),
    });
  };

  const fetchStudent = (filter) => {
    setStudentList({
      api: () => api.fetchStudent(filter),
      res: (data) => data.data,
    });
  };

  useEffect(() => {
    fetchBatchYear();
  }, []);

  useEffect(() => {
    if (batchYearState.total !== 0) {
      const currentBatch = batchYearState.data[0].batchYear;
      formik.setFieldValue("batch", currentBatch);
      fetchStudent({ ...formik.values, batch: currentBatch });

      fetchClassSection();
      fetchDiscount();
      const headerList = sessionStorage.getItem("table_header");
      if (headerList) {
        setTableHeaderState(JSON.parse(headerList));
      }
      const studentFilter = JSON.parse(
        sessionStorage.getItem("student_filter")
      );

      if (studentFilter) {
        formik.setValues(studentFilter.values);
        setClassIndex(studentFilter.classIndex);
      }
    }
  }, [batchYearState]);

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        alertval={alertv}
        alertval2={alertval}
      ></Modal>

      <Tablebox>
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "7px",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Students
            </Typography>
            <AddStudent
              year={formik.values.batch}
              filtervalue={formik.values.batch}
              button="Add Student"
              classNameList={classNameState}
              discountList={discountState}
              studentFilter={formik.values}
              tableHeaderList={tableHeaderState
                .filter((header) =>
                  formik.values.batch === "All" ? header.all : true
                )
                .filter((header) => header.show || header.isDefault)
                .map((header) => header.exporthead)}
            />
          </Box>
          <Typography variant="p" sx={{ fontWeight: "600" }}>
            Filters :
          </Typography>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              my: "20px",
              flexWrap: "wrap",
              gap: "15px",
            }}
          >
            <FormControl sx={{ minWidth: "150px" }}>
              <InputLabel size="small" id="demo-simple-select-label">
                Batch
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="batch"
                value={formik.values.batch}
                label="Batch"
                name="batch"
                onChange={formik.handleChange}
              >
                <MenuItem value="All">All</MenuItem>
                {batchYearState?.data.map((ye, key) => {
                  return (
                    <MenuItem key={key} value={ye.batchYear}>
                      {ye.batchYear}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "150px" }}>
              <InputLabel size="small" id="demo-simple-select-label">
                Paid
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="paid"
                value={formik.values.paid}
                label="Paid"
                name="paid"
                onChange={formik.handleChange}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="nopaid">No Paid</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "150px" }}>
              <InputLabel size="small" id="demo-simple-select-label">
                discontinued
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="discontinued"
                value={formik.values.discontinued}
                label="discontinued"
                name="discontinued"
                onChange={formik.handleChange}
              >
                <MenuItem value="1">NO</MenuItem>
                <MenuItem value="0">YES</MenuItem>
              </Select>
            </FormControl>

            {formik.values.batch !== "All" && (
              <>
                <FormControl sx={{ minWidth: "150px" }}>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Class Name
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="className"
                    value={formik.values.className}
                    label="Class Name"
                    name="className"
                    onChange={formik.handleChange}
                  >
                    <MenuItem onClick={() => setClassIndex(null)} value="All">
                      All
                    </MenuItem>
                    {classNameState?.data.map((ye, key) => {
                      return (
                        <MenuItem
                          key={key}
                          onClick={() => setClassIndex(key)}
                          value={ye.className}
                        >
                          {ye.className}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: "150px" }}>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Section Name
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="sectionName"
                    value={formik.values.sectionName}
                    label="Section Name"
                    name="sectionName"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {classNameState.data[classIndex]?.sectionName
                      ?.split(",")
                      ?.map((secName) => {
                        return <MenuItem value={secName}>{secName}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: "150px" }}>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Discount Name
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="discountUuid"
                    name="discountUuid"
                    label="Discount Name"
                    value={formik.values.discountUuid}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="None">None</MenuItem>
                    {discountState?.data.map((discount, key) => {
                      return (
                        <MenuItem key={key} value={discount.uniqueId}>
                          {discount.discountName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
            <TextField
              size="small"
              sx={{ maxWidth: "150px" }}
              id="studentName"
              name="studentName"
              onChange={formik.handleChange}
              value={formik.values.studentName}
              label="SN/EN/SI"
              variant="outlined"
            />
            <TextField
              size="small"
              sx={{ maxWidth: "150px" }}
              id="balance"
              name="balance"
              onChange={formik.handleChange}
              value={formik.values.balance}
              label="Balance"
              variant="outlined"
            />
            <Button
              variant="contained"
              size="large"
              type="submit"
              sx={{ color: "#fff" }}
            >
              Search
            </Button>
          </Box>

          <TableHeaderCustomize
            options={tableHeaderState.filter((header) =>
              formik.values.batch === "All" ? header.all : true
            )}
            handleTableHeaders={handleTableHeaders}
          />
          <Table2
            columns={tableHeaderState.filter((header) =>
              formik.values.batch === "All" ? header.all : true
            )}
            totalSize={studentList.totalSize}
            rows={studentView}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            pagination={{ page: formik.values.page, size: formik.values.size }}
            classNameList={classNameState}
            discountList={discountState}
          />
        </div>
      </Tablebox>
    </>
  );
};

export default Student;
