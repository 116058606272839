import * as React from "react";
import styles from "./Print.module.css";
import logo from "../../Assets/logo.jpeg";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const v = props.v;
  const studentDetails = props.studentDetails;
  // const val = v.feesPaidHistoryList.at(-1);
  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " @ " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();
  return (
    <div className={styles.print_source} ref={ref}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <img src={logo} width={90}></img>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className={styles.size_1} style={{ margin: 0 }}>
            Hr.Sec : 6385648341
          </p>
          <p className={styles.size_1} style={{ margin: 0 }}>
            Primary : 6385485127
          </p>
          <h5 style={{ margin: 0, background: "#000", color: "#fff" }}>
            SRI VIVEKANANDA
          </h5>
          <h5 style={{ margin: 0, background: "#000", color: "#fff" }}>
            HIGHER SECONDARY SCHOOL
          </h5>
          <h6 style={{ margin: 0, marginTop: 2 }}>
            EARVAIPATTINAM (PO), KACHIRAYAPALAYAM
          </h6>
        </div>
      </div>
      <hr></hr>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div>
          <p
            className={styles.size_1}
            style={{ margin: 0 }}
          >{`Name: ${studentDetails?.name}`}</p>
          <p className={styles.size_1} style={{ margin: 0 }}>
            {`Father: ${props.parentDetail?.fatherName}`}{" "}
          </p>
          <p className={styles.size_1} style={{ margin: 0 }}>
            {`Mother: ${props.parentDetail?.motherName}`}{" "}
          </p>
          <p className={styles.size_1} style={{ margin: 0 }}>
            {`Student Id: ${studentDetails?.studentId}`}{" "}
          </p>
        </div>
        <div style={{ textAlign: "right" }}>
          <p className={styles.size_1} style={{ margin: 0 }}>
            {`Batch: ${props.batchYear}`}
          </p>
          <p className={styles.size_1} style={{ margin: 0 }}>
            {`Class: ${v.studentAcademicDetailResponseDto?.className} / ${v.studentAcademicDetailResponseDto?.sectionName}`}
          </p>
          <p
            style={{ margin: 0 }}
            className={styles.size_1}
          >{`Fees To Be Paid: ${v?.paymentInputOptions?.academicTotalFeesToBePaid.toLocaleString(
            "en-IN"
          )}`}</p>
          <p
            style={{ margin: 0 }}
            className={styles.size_1}
          >{`Fees Paid: ${v?.paymentInputOptions?.academicTotalFeesPaid.toLocaleString(
            "en-IN"
          )}`}</p>
          <hr></hr>
          <p
            className={styles.size_1}
            style={{ margin: 0 }}
          >{`OverAll Balance: ${studentDetails?.balance.toLocaleString(
            "en-IN"
          )}`}</p>
          <p
            className={styles.size_1}
            style={{ margin: 0 }}
          >{`Issued On: ${datetime}`}</p>
        </div>
      </div>
      {v.feesPaidHistoryList.map((val) => (
        <>
          <hr></hr>
          <p
            className={styles.size_1}
            style={{ margin: 0 }}
          >{`Payment On: ${val.paymentOn}`}</p>
          <table className={styles.fees_table} style={{ width: "100%" }}>
            {val.term1 > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Term 1</th>
                <td style={{ textAlign: "right" }}>
                  {val.term1.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            {val.term2 > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Term 2</th>
                <td style={{ textAlign: "right" }}>
                  {val.term2.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            {val.term3 > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Term 3</th>
                <td style={{ textAlign: "right" }}>
                  {val.term3.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            {val.bookFees > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Book Fees</th>
                <td style={{ textAlign: "right" }}>
                  {val.bookFees.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            {val.labFees > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Lab Fees</th>
                <td style={{ textAlign: "right" }}>
                  {val.labFees.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            {val.twoWayFees > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Two Way Fees</th>
                <td style={{ textAlign: "right" }}>
                  {val.twoWayFees.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            {val.specialTrip > 0 && (
              <tr>
                <th style={{ textAlign: "left" }}>Special Trip Fees</th>
                <td style={{ textAlign: "right" }}>
                  {val.specialTrip.toLocaleString("en-IN")}
                </td>
              </tr>
            )}
            <tr>
              <th style={{ textAlign: "left" }}>Total</th>
              <td style={{ textAlign: "right", borderTop: "1px solid black" }}>
                {val.totalFees.toLocaleString("en-IN")}
              </td>
            </tr>
          </table>
        </>
      ))}
      <div style={{ display: "block", marginTop: 10 }}>
        <h6 style={{ textAlign: "right" }}>
          For: Sri Vivekanandha Hr Sec School
        </h6>
      </div>
    </div>
  );
});
