import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { theme } from "../../Layouts/Themesetup/index";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const AddDiscount = ({ postDiscount }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      discountName: "",
      busFees: false,
      tuitionFees: false,
      bookFees: false,
      labFees: false,
      discountPercentage: 0,
    },
    validationSchema: Yup.object({
      discountName: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      postDiscount({
        ...values,
        discountName: values.discountName.toUpperCase(),
      });
      handleClose();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleOpen}
          variant="contained"
          size="medium"
          color="buttoncolor"
          sx={{ color: "#fff" }}
        >
          Add Discount
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 2, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle>Add Discount</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                style={{ margin: 0, marginBottom: 0 }}
                name="discountName"
                id="discountName"
                label="Discount Name*"
                value={formik.values.discountName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.discountName && formik.errors.discountName ? (
                <div style={{ color: "red" }}>{formik.errors.discountName}</div>
              ) : null}
              {!formik.values.discountPercentage && (
                <>
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    control={
                      <Checkbox
                        id="busFees"
                        name="busFees"
                        checked={formik.values.busFees && "checked"}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Bus Fees"
                  />
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    control={
                      <Checkbox
                        name="tuitionFees"
                        id="tuitionFees"
                        checked={formik.values.tuitionFees && "checked"}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Tution Fees"
                  />
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    control={
                      <Checkbox
                        name="bookFees"
                        id="bookFees"
                        checked={formik.values.bookFees && "checked"}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Book Fees"
                  />
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    control={
                      <Checkbox
                        name="labFees"
                        id="labFees"
                        checked={formik.values.labFees && "checked"}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Lab Fees"
                  />
                </>
              )}
              {!formik.values.busFees &&
                !formik.values.tuitionFees &&
                !formik.values.bookFees &&
                !formik.values.labFees && (
                  <>
                    <TextField
                      style={{ margin: 0, marginTop: "20px" }}
                      type="number"
                      name="discountPercentage"
                      id="discountPercentage"
                      label="Discount Percentage"
                      value={formik.values.discountPercentage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.discountPercentage &&
                    formik.errors.discountPercentage ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.discountPercentage}
                      </div>
                    ) : null}
                  </>
                )}
            </div>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              disabled={!formik.values.discountName && "disabled"}
              color="buttoncolor"
              sx={{ color: "#fff" }}
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddDiscount;
