import { FileUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Table3 from "../../Components/Table/Table3";
import Table4 from "../../Components/Table/Table4.js";

const columns = [
  { id: "className", align: "center", label: "Class Name", minWidth: 130 },
  {
    id: "totalNoOfStudent",
    align: "center",
    label: "No of Students",
    minWidth: 130,
    border: true,
  },
  {
    id: "totalTuitionFees",
    label: "Tuition Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalBookFees",
    label: "Book Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalLabFees",
    label: "Lab Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalBusFees",
    label: "Bus Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    border: true,
  },
  {
    id: "totalDiscountTuitionFees",
    label: "Tuition Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalDiscountBookFees",
    label: "Book Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalDiscountLabFees",
    label: "Lab Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalDiscountBusFees",
    label: "Bus Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    border: true,
  },
  {
    label: `Tuition Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) => row.totalTuitionFees - row.totalDiscountTuitionFees,
  },
  {
    label: `Book Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) => row.totalBookFees - row.totalDiscountBookFees,
  },
  {
    label: `Lab Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) => row.totalLabFees - row.totalDiscountLabFees,
  },
  {
    label: `Bus Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) => row.totalBusFees - row.totalDiscountBusFees,
    border: true,
  },
  {
    id: "paidTotalTuitionFees",
    label: "Tuition Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "paidTotalBookFees",
    label: "Book Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "paidTotalLabFees",
    label: "Lab Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "paidTotalBusFees",
    label: "Bus Fees",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    border: true,
  },
  {
    label: `Tuition Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) =>
      row.totalTuitionFees -
      row.totalDiscountTuitionFees -
      row.paidTotalTuitionFees,
  },
  {
    label: `Book Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) =>
      row.totalBookFees - row.totalDiscountBookFees - row.paidTotalBookFees,
  },

  {
    label: `Lab Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    calculate: (row) =>
      row.totalLabFees - row.totalDiscountLabFees - row.paidTotalLabFees,
  },
  {
    label: `Bus Fees`,
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    border: true,
    calculate: (row) =>
      row.totalBusFees - row.totalDiscountBusFees - row.paidTotalBusFees,
  },
  {
    calculate: (row) =>
      row.totalDiscountTuitionFees +
      row.totalDiscountBookFees +
      row.totalDiscountLabFees +
      row.totalDiscountBusFees,
    label: "Total Discount",
    minWidth: 180,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    calculate: (row) =>
      row.totalTuitionFees -
      row.totalDiscountTuitionFees +
      row.totalBookFees -
      row.totalDiscountBookFees +
      row.totalBusFees -
      row.totalDiscountBusFees +
      row.totalLabFees -
      row.totalDiscountLabFees,

    label: `Total Fees`,
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    calculate: (row) =>
      row.paidTotalTuitionFees +
      row.paidTotalBookFees +
      row.paidTotalBusFees +
      row.paidTotalLabFees,
    label: `Total Paid Fees`,
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    calculate: (row) =>
      row.totalTuitionFees -
      row.totalDiscountTuitionFees -
      row.paidTotalTuitionFees +
      (row.totalBookFees - row.totalDiscountBookFees - row.paidTotalBookFees) +
      (row.totalBusFees - row.totalDiscountBusFees - row.paidTotalBusFees) +
      (row.totalLabFees - row.totalDiscountLabFees - row.paidTotalLabFees),
    label: `Total Balance`,
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
    border: true,
  },
];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const Dashboard = () => {
  const [summaryFeesList, setSummaryFeesList] = useFetch({ data: [] });
  const [yearData, setYearData] = useFetch({ data: [], total: 0 });
  const [pagination, setPagination] = useState({ page: 0, size: 100 });
  const [currentBatch, setCurrentBatch] = useState("");

  const fetchBatchYear = () => {
    setYearData({
      api: () => api.fetchYear(pagination),
      res: (data) => ({
        data: data.data.academicYearDetailsDtoListList,
        total: data.data.total,
      }),
    });
  };

  const getSummaryfees = (year) => {
    setSummaryFeesList({
      api: () => api.fetchSummaryfees(year),
      res: (data) => ({ data: data.data }),
    });
  };

  useEffect(() => {
    fetchBatchYear();
  }, []);

  useEffect(() => {
    if (yearData.total !== 0) {
      const year = yearData.data[0].batchYear;
      setCurrentBatch(year);
    }
  }, [yearData]);

  useEffect(() => {
    if (currentBatch !== "") {
      getSummaryfees(currentBatch);
    }
  }, [currentBatch]);

  return (
    <>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Overall Summary
          </Typography>

          <FormControl sx={{ minWidth: "150px" }}>
            <InputLabel id="demo-simple-select-label-batch">Batch</InputLabel>
            <Select
              labelId="demo-simple-select-label-batch"
              id="demo-simple-select-batch"
              value={currentBatch}
              label="Age"
              onChange={(event) => setCurrentBatch(event.target.value)}
            >
              {yearData?.data.map((ye, key) => {
                return (
                  <MenuItem key={key} value={ye.batchYear}>
                    {ye.batchYear}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {/* <Table3
          columns={columns}
          rows={summaryFeesList.data}
          visibilityIcon={false}
          editIcon={false}
          deleteIcon={false}
        /> */}
        <Table4
          columns={columns}
          rows={summaryFeesList.data.feesSummaryResponsesList}
        />

        <Typography
          variant="h6"
          sx={{ fontWeight: "600", marginTop: "50px", marginBottom: "50px" }}
        >
          Discontinued Overall Summary
        </Typography>

        <Table4
          columns={columns}
          rows={summaryFeesList.data.discountinuedfeesSummaryResponsesList}
        />
      </Tablebox>
    </>
  );
};

export default Dashboard;
