import { FileUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Table3 from "../../Components/Table/Table3";
import AddBus from "./Addbus";
import EditBus from "./EditBus";

const columns = [
  { id: "routeName", align: "center", label: "Route Name", minWidth: 100 },
  { id: "busNumber", align: "center", label: "Bus\u00a0No", minWidth: 100 },
  {
    id: "twoWayFees",
    label: "Regular Fees",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "specialTrip",
    label: "Special Trip Fees",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalFees",
    label: "Total Fees",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-IN"),
  },
];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const Bus = () => {
  const [busFeesList, setBusFeesList] = useFetch({ data: [] });
  const [yearData, setYearData] = useFetch({ data: [], total: 0 });
  const [exportFile, setExportFile] = useFetch(null);
  const [pagination, setPagination] = useState({ page: 0, size: 100 });
  const [currentBatch, setCurrentBatch] = useState("");
  const fileExportRef = useRef(null);

  const fetchBatchYear = () => {
    setYearData({
      api: () => api.fetchYear(pagination),
      res: (data) => ({
        data: data.data.academicYearDetailsDtoListList,
        total: data.data.total,
      }),
    });
  };

  const getBusfees = (year) => {
    setBusFeesList({
      api: () => api.fetchBusfees(year),
      res: (data) => ({ data: data.data.busFeesList }),
    });
  };

  const postBusfees = (newfees) => {
    setBusFeesList({
      api: () => api.createBusfees([newfees]),
      res: (data) => ({
        data: [...busFeesList.data, ...data.data.busFeesList],
      }),
    });
  };

  const updateBusfees = (val) => {
    setBusFeesList({
      api: () => api.updateBusfees(val),
      res: (data) => {
        const finalBusFeesList = busFeesList.data.filter(
          (busFee) => busFee.uniqueId !== val.uniqueId
        );
        val.totalFees = val.twoWayFees + val.specialTrip;
        finalBusFeesList.push(val);
        return { data: finalBusFeesList };
      },
    });
  };

  const deleteBusfees = (val) => {
    setBusFeesList({
      api: () => api.deleteBusfees(val.uniqueId, val.batchYear),
      res: (data) => {
        const finalBusFeesList = busFeesList.data.filter(
          (busFee) => busFee.uniqueId !== val.uniqueId
        );
        return { data: finalBusFeesList };
      },
    });
  };

  const handleExportFile = () => {
    setExportFile({
      api: () => api.exportBusFees(currentBatch),
      res: (data) => ({ fileUuid: data.data }),
    });
  };

  const [isEdit, setIsEdit] = useState(false);
  const [editBusFee, setEditBusFee] = useState(null);
  const openBusFeeEdit = (busFee) => {
    setIsEdit(true);
    setEditBusFee(busFee);
  };

  useEffect(() => {
    fetchBatchYear();
  }, []);

  useEffect(() => {
    if (yearData.total !== 0) {
      const year = yearData.data[0].batchYear;
      setCurrentBatch(year);
    }
  }, [yearData]);

  useEffect(()=>{
    if(currentBatch!==""){
      getBusfees(currentBatch);
    }
  },[currentBatch])
  

  useEffect(() => {
    if (exportFile != null) {
      fileExportRef.current.click();
    }
  }, [exportFile]);

  return (
    <>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Bus Fees
          </Typography>
          <a
            href={`https://svsschool.co.in/services/svs/api/busFees/exportFile/${exportFile?.fileUuid}`}
            ref={fileExportRef}
            target="_blank"
            rel="noreferrer"
            style={{display:'none'}}
          ></a>

          <FormControl sx={{ minWidth: "150px" }}>
            <InputLabel id="demo-simple-select-label-batch">Batch</InputLabel>
            <Select
              labelId="demo-simple-select-label-batch"
              id="demo-simple-select-batch"
              value={currentBatch}
              label="Age"
              onChange={(event) => setCurrentBatch(event.target.value)}
            >
              {yearData?.data.map((ye, key) => {
                return (
                  <MenuItem key={key} value={ye.batchYear}>
                    {ye.batchYear}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div style={{display:'flex', gap: 15}}>
          <Button variant="outlined" onClick={handleExportFile} color="primary">
            {<FileUpload />} Export
          </Button>
          <AddBus postBusfees={postBusfees} currentBatch={currentBatch} />
          </div>
          {isEdit && (
            <EditBus
              setIsEdit={setIsEdit}
              editBusFee={editBusFee}
              updateBusfees={updateBusfees}
            />
          )}
        </Box>
        <Table3
          columns={columns}
          rows={busFeesList.data}
          openEditModel={openBusFeeEdit}
          deleteHandler={deleteBusfees}
          visibilityIcon={false}
        />
        {/* <Table columns={columns} setBusid={setBusid} rows={busfees.busfees} /> */}
      </Tablebox>
    </>
  );
};

export default Bus;
