import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch.js";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Table3 from "../../Components/Table/Table3.js";
import { FileUpload } from "@mui/icons-material";

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 98,
  marginRight: 18,
  borderRadius: 10,
  padding: theme.spacing(2, 2),
  boxShadow: "0px 0px 10px #0000000a",
  background: "#fff",
}));

const columns = [
  { id: "name", align: "center", label: "Student Name", minWidth: 100 },
  { id: "className", align: "center", label: "Class", minWidth: 100 },
  {
    id: "sectionName",
    label: "Section",
    minWidth: 100,
    align: "center",
  },
  {
    id: "emisNo",
    label: "Emis No",
    minWidth: 100,
    align: "center",
  },
  {
    id: "term1",
    label: "Term1",
    minWidth: 100,
    align: "center",
  },
  {
    id: "term2",
    label: "Term2",
    minWidth: 100,
    align: "center",
  },
  {
    id: "term3",
    label: "Term3",
    minWidth: 100,
    align: "center",
  },
  {
    id: "bookFees",
    label: "Book Fees",
    minWidth: 100,
    align: "center",
  },
  {
    id: "twoWayFees",
    label: "TwoWay Fees",
    minWidth: 100,
    align: "center",
  },
  {
    id: "specialTripFees",
    label: "SpecialTrip Fees",
    minWidth: 100,
    align: "center",
  },
  {
    id: "labFees",
    label: "Lab Fees",
    minWidth: 100,
    align: "center",
  },
  {
    id: "paidFees",
    label: "Total Paid Fees",
    minWidth: 100,
    align: "center",
  },
  {
    id: "batchYear",
    label: "Batch Year",
    minWidth: 100,
    align: "center",
  },
  {
    id: "payedOn",
    label: "Paid On(MM-DD-YYYY)",
    minWidth: 100,
    align: "center",
  },
];

const FeesPaymentHistory = () => {
  const [classNameState, setClassNameState] = useFetch({ data: [] });
  const [paymentHistoryList, setPaymentHistory] = useFetch({
    data: [],
    size: 0,
  });
  const [exportFile, setExportFile] = useFetch(null);

  const [fromDate, setFrom] = React.useState(null);
  const [toDate, setTo] = React.useState(null);
  const [pagination, setPagination] = useState({ page: 0, size: 10 });
  const fileExportRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: null,
      className: "All",
      emisNo: null,
    },
    onSubmit: (values) => {
      console.log("values", values);
      setPagination({ ...pagination, page: 0 });
      fetchPaymentHistory({
        ...values,
        page: 0,
        size: pagination.size,
      });
    },
  });

  const handleChangePage = (e, val) => {
    setPagination({ ...pagination, page: val });
    fetchPaymentHistory({
      ...formik.values,
      page: val,
      size: pagination.size,
    });
  };

  const handleChangeRowsPerPage = (e, val) => {
    setPagination({ ...pagination, size: e.target.value });
    fetchPaymentHistory({
      ...formik.values,
      size: e.target.value,
      page: pagination.page,
    });
  };

  const fetchClassSection = () => {
    setClassNameState({
      api: () => api.fetchClass(),
      res: (data) => ({ data: data.data.classSectionList }),
    });
  };

  const fetchPaymentHistory = (filter, setInitalDate = false) => {
    const finalFilter = { ...filter };
    if (setInitalDate) {
      const from = new Date().toISOString().slice(0, 10);
      const nextMonth = new Date(new Date());
      nextMonth.setMonth(new Date().getMonth() + 1);
      const to = nextMonth.toISOString().slice(0, 10);
      setFrom(dayjs(from));
      setTo(dayjs(to));
      finalFilter.from = from;
      finalFilter.to = to;
    } else if ((fromDate != null) & (toDate != null)) {
      finalFilter.from = fromDate.format("YYYY-MM-DD");
      finalFilter.to = toDate.format("YYYY-MM-DD");
    }

    setPaymentHistory({
      api: () =>
        api.findAllPaymentHistory({
          ...finalFilter,
          className:
            finalFilter.className === "All" ? null : finalFilter.className,
        }),
      res: (data) => ({ data: data.data.data, size: data.data.size }),
    });
  };

  const handleExportFile = () => {
    const finalFilter = { ...formik.values };
    finalFilter.from = fromDate.format("YYYY-MM-DD");
    finalFilter.to = toDate.format("YYYY-MM-DD");
    finalFilter.className =
      finalFilter.className === "All" ? null : finalFilter.className;
    setExportFile({
      api: () => api.exportPaymentHistory(finalFilter),
      res: (data) => ({ fileUuid: data.data }),
    });
  };
  useEffect(() => {
    fetchPaymentHistory(
      {
        ...formik.values,
        page: pagination.page,
        size: pagination.size,
      },
      true
    );
    fetchClassSection();
  }, []);

  useEffect(() => {
    if (exportFile != null) {
      fileExportRef.current.click();
    }
  }, [exportFile]);

  return (
    <>
      <Tablebox>
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "7px",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Fees Payment History
            </Typography>
            <a
              href={`https://svsschool.co.in/services/svs/api/feesPayment/paymentHistory/exportFile/${exportFile?.fileUuid}`}
              ref={fileExportRef}
              target="_blank"
              rel="noreferrer"
              style={{ display: "none" }}
            ></a>
            <Button
              variant="outlined"
              onClick={handleExportFile}
              color="primary"
            >
              {<FileUpload />} Export
            </Button>
          </Box>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              my: "20px",
              flexWrap: "wrap",
              gap: "15px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From (MM-DD-YYYY)"
                value={fromDate}
                minDate={dayjs("2017-01-01")}
                onChange={(newValue) => {
                  setFrom(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="To (MM-DD-YYYY)"
                value={toDate}
                minDate={dayjs("2017-01-01")}
                onChange={(newValue) => {
                  setTo(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <FormControl sx={{ minWidth: "150px" }}>
              <InputLabel size="small" id="demo-simple-select-label">
                Class Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="className"
                value={formik.values.className}
                label="Class Name"
                name="className"
                onChange={formik.handleChange}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"NA"}>NA</MenuItem>
                {classNameState?.data.map((ye, key) => {
                  return (
                    <MenuItem key={key} value={ye.className}>
                      {ye.className}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              sx={{ maxWidth: "200px" }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              label="Name"
              variant="outlined"
            />
            <TextField
              sx={{ maxWidth: "200px" }}
              id="emisNo"
              name="emisNo"
              onChange={formik.handleChange}
              value={formik.values.emisNo}
              label="Emis No"
              variant="outlined"
            />
            <Button
              variant="contained"
              size="large"
              type="submit"
              sx={{ color: "#fff" }}
            >
              Search
            </Button>
          </Box>
        </div>
        <Table3
          columns={columns}
          rows={paymentHistoryList.data}
          visibilityIcon={false}
          editIcon={false}
          deleteIcon={false}
          totalSize={paymentHistoryList.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          pagination={pagination}
        />
      </Tablebox>
    </>
  );
};

export default FeesPaymentHistory;
