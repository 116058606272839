import { AccountCircle, ArrowBackIos } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Paper from "@mui/material/Paper";
import { styled, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import { theme } from "../../Layouts/Themesetup/index";
import AddPayment from "./Addpayment";
import Addprevdata from "./Addprevpayment";
import Editstudent from "./Editstudent";
import Editbatch from "./Editbatch.js";
import { ViewGrid } from "../../Components/Viewtypo/index.js";
import { useNavigate } from "react-router-dom";
import Table3 from "../../Components/Table/Table3.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { ComponentToPrint } from "./ComponentPrint.js";
import { useReactToPrint } from "react-to-print";

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 50,
  marginLeft: 80,
  padding: theme.spacing(3, 3),
}));

const feesPaymentcolumns = [
  {
    id: "term1",
    align: "center",
    label: "Term 1",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "term2",
    align: "center",
    label: "Term 2",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "term3",
    align: "center",
    label: "Term 3",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "bookFees",
    align: "center",
    label: "Book Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "labFees",
    align: "center",
    label: "Lab Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "twoWayFees",
    align: "center",
    label: "Two Way Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "specialTrip",
    align: "center",
    label: "Special Trip Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalFees",
    align: "center",
    label: "Total Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "paymentOn",
    align: "center",
    label: "Payment On",
    minWidth: 100,
  },
];

const ViewStudent = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const location = useLocation();

  const [studentHistoryDetails, setStudentHistoryDetails] = useFetch(null);
  const [paymentDetails, setPayentDetails] = useFetch(null);
  const [prevPaymentDetails, setPrevPayentDetails] = useFetch(null);
  const [editStudentDetails, setEditStudentDetails] = useFetch(null);
  const [editBatchDetails, setEditBatchDetails] = useFetch(null);
  const [paymentRecord, setDeletePaymentRecord] = useFetch(null);
  const [previousPaymentRecord, setDeletePreviousPaymentRecord] =
    useFetch(null);
  const [discontinued, setDiscontinued] = useFetch(null);
  const [deleteStudentAcademicDetails, setDeleteStudentAcademicDetails] =
    useFetch(null);

  const discontinueStudent = (val1, val2) => {
    setDiscontinued({ api: () => api.discontinueStudent(val1, val2) });
  };

  const deleteStudentFun = (val1, val2) => {
    setDeleteStudentAcademicDetails({
      api: () => api.deleteStudentAcademicDetails(val1, val2),
    });
  };

  const componentRef = [];

  let globalIndex = 0;
  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef[globalIndex].current;
    },
  });

  const viewStudent = () => {
    setStudentHistoryDetails({
      api: () => api.viewStudent(uuid),
      res: (data) => ({ ...data.data }),
    });
  };

  const addPayment = (val) => {
    setPayentDetails({
      api: () => api.payStudent(val),
    });
  };

  const prevPayment = (val) => {
    setPrevPayentDetails({
      api: () => api.prevPayStudent(val),
    });
  };

  const paymentRecordDeleteHandler = (item) => {
    setDeletePaymentRecord({
      api: () => api.deletePaymentRecord(item.uniqueId),
    });
  };

  const deletePreviousFeesRecord = (id) => {
    setDeletePaymentRecord({
      api: () => api.deletePreviousPayment(id),
    });
  };

  useEffect(() => {
    viewStudent();
  }, [
    paymentDetails,
    editStudentDetails,
    editBatchDetails,
    prevPaymentDetails,
    paymentRecord,
    previousPaymentRecord,
    discontinued,
    deleteStudentAcademicDetails,
  ]);

  const miniTable = studentHistoryDetails?.miniTable
    ? Object.keys(studentHistoryDetails?.miniTable).map((key, index) => {
        return { year: key, amount: studentHistoryDetails?.miniTable[key] };
      })
    : [];

  const studentDetail = studentHistoryDetails?.studentDetailResponseDto;
  const parentDetail = studentHistoryDetails?.parentDetailResponseDto;
  const siblingDetail = studentHistoryDetails?.siblingsResponseDtoList;

  const currentClasssection =
    studentHistoryDetails?.studentBatchResponseMap[
      studentHistoryDetails?.currentBatchYear
    ].studentAcademicDetailResponseDto;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Tablebox>
          <Box>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to={"/student"}>
                <Button
                  variant="contained"
                  color="lightblack"
                  style={{
                    height: "50px",
                    boxShadow: "unset",
                    minWidth: "30px",
                  }}
                >
                  <ArrowBackIos style={{ marginLeft: 5, color: "#000" }} />
                </Button>
              </Link>
              <Typography
                variant="h6"
                sx={{ width: "100%", textAlign: "center", fontWeight: 500 }}
              >
                View Details
              </Typography>
              <Editstudent
                button="Edit"
                studentDetail={studentDetail}
                parentDetail={parentDetail}
                setEditStudentDetails={setEditStudentDetails}
              ></Editstudent>
            </div>
            <div style={{ marginTop: 10 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <AccountCircle sx={{ fontSize: 130 }} />
                  </div>
                </Grid>
                <ViewGrid
                  label1="Student Name"
                  text1={studentDetail?.name}
                  label2="Student ID"
                  text2={studentDetail?.studentId}
                  label3="Date of Birth"
                  text3={studentDetail?.dob}
                />
                <ViewGrid
                  label1="Class / Section"
                  text1={`${currentClasssection?.className} / ${currentClasssection?.sectionName}`}
                  label2="Emis No"
                  text2={studentDetail?.emisNo}
                  label3="Gender"
                  text3={studentDetail?.gender}
                />
                <ViewGrid
                  label1="Student Caste"
                  text1={studentDetail?.studentCaste}
                  label2="Student Religion"
                  text2={studentDetail?.studentReligion}
                  label3=" Previous Balance - Feel Till 2021"
                  text3={studentDetail?.previousUnregisteredYearBalance}
                />
                <ViewGrid
                  label1="Fees to be paid"
                  text1={studentDetail?.feesToBePaid}
                  label2="Total Fees Paid"
                  text2={studentDetail?.totalFeesPaid}
                  label3="Total Balance"
                  text3={studentDetail?.balance}
                />
                <Grid item xs={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 80 }} aria-label="simple table">
                      <TableBody>
                        {miniTable?.map((e, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {e.year} :
                            </TableCell>
                            <TableCell align="right">{e.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            {/* <div>
              <Typography
                variant="h6"
                sx={{
                  marginTop: 2,
                  width: "100%",
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Batch Details
              </Typography>
            </div> */}

            {studentHistoryDetails?.studentBatchResponseMap &&
              Object.entries(
                studentHistoryDetails?.studentBatchResponseMap
              ).map(([key, v], index) => {
                const newRef = React.createRef();

                componentRef.push(newRef);
                return (
                  <div style={{ marginTop: 30 }}>
                    <Accordion
                      key={v?.studentAcademicDetailResponseDto?.batchYear}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={{ marginRight: 2 }}>
                            Batch:{" "}
                            {v?.studentAcademicDetailResponseDto?.batchYear}
                          </Typography>
                          <Editbatch
                            button="Edit Batch"
                            batchDetail={v?.studentAcademicDetailResponseDto}
                            setEditBatchDetails={setEditBatchDetails}
                            classNameList={location.state.classNameList}
                            discountList={location.state.discountList}
                            uuid={uuid}
                          ></Editbatch>
                          <div
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                discontinueStudent(
                                  v?.studentAcademicDetailResponseDto
                                    ?.batchYear,
                                  uuid
                                );
                              }}
                              variant="outlined"
                              size="medium"
                              color="buttoncolor"
                            >
                              {v?.studentAcademicDetailResponseDto
                                ?.discontinued === 1
                                ? "Discontinue"
                                : "Continue"}
                            </Button>
                          </div>

                          {v?.paymentInputOptions?.issues?.length > 0 && (
                            <>
                              <p style={{ color: "red", marginLeft: "10" }}>
                                Misleading Calculation. Click here and view Fees
                                Structure for more info
                              </p>
                            </>
                          )}
                          {v?.paymentInputOptions?.academicBalance !== 0 ? (
                            <>
                              <p
                                style={{
                                  backgroundColor: "yellow",
                                  marginLeft: "10px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                Not Paid
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  backgroundColor: "#05f545",
                                  marginLeft: "10px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                Paid
                              </p>
                            </>
                          )}

                          <div
                            style={{
                              marginLeft: "500px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                deleteStudentFun(
                                  v?.studentAcademicDetailResponseDto
                                    ?.batchYear,
                                  uuid
                                );
                              }}
                              variant="outlined"
                              size="medium"
                              color="error"
                            >
                              DELETE
                            </Button>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Button
                            variant="contained"
                            onClick={() => {
                              globalIndex = index;
                              handlePrint();
                            }}
                          >
                            Print this out!
                          </Button>

                          <ComponentToPrint
                            ref={newRef}
                            v={v}
                            studentDetails={studentDetail}
                            batchYear={key}
                            parentDetail={parentDetail}
                          />
                        </div>
                        <Grid container style={{ marginTop: 2 }} spacing={2}>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Class Name
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {v?.studentAcademicDetailResponseDto?.className}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Section Name
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {
                                  v?.studentAcademicDetailResponseDto
                                    ?.sectionName
                                }
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Discount Name
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {v?.studentAcademicDetailResponseDto
                                  ?.discountName || "-"}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Director Discount
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {
                                  v?.studentAcademicDetailResponseDto
                                    ?.directorDiscount
                                }
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Director Bus Discount
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {
                                  v?.studentAcademicDetailResponseDto
                                    ?.directorBusDiscount
                                }
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Discount Reason
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {
                                  v?.studentAcademicDetailResponseDto
                                    ?.discountReason
                                }
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Academic Balance
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {v?.paymentInputOptions?.academicBalance}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Fees To Be Paid
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {
                                  v?.paymentInputOptions
                                    ?.academicTotalFeesToBePaid
                                }
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Total Fees Paid
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {v?.paymentInputOptions?.academicTotalFeesPaid}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div>
                              <Typography
                                variant="p"
                                color="secondary"
                                sx={{
                                  width: "100%",
                                  fontSize: 14,
                                  textAlign: "left",
                                }}
                              >
                                Group ID
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                              >
                                {v?.studentAcademicDetailResponseDto?.groupId ||
                                  "-"}
                              </Typography>
                            </div>
                          </Grid>
                          {v?.studentAcademicDetailResponseDto?.busService && (
                            <>
                              <Grid item xs={2}>
                                <div>
                                  <Typography
                                    variant="p"
                                    color="secondary"
                                    sx={{
                                      width: "100%",
                                      fontSize: 14,
                                      textAlign: "left",
                                    }}
                                  >
                                    Route Name
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      width: "100%",
                                      textAlign: "left",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {
                                      v?.studentAcademicDetailResponseDto
                                        ?.routeName
                                    }
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div>
                                  <Typography
                                    variant="p"
                                    color="secondary"
                                    sx={{
                                      width: "100%",
                                      fontSize: 14,
                                      textAlign: "left",
                                    }}
                                  >
                                    Bus Number
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      width: "100%",
                                      textAlign: "left",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {
                                      v?.studentAcademicDetailResponseDto
                                        ?.busNumber
                                    }
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div>
                                  <Typography
                                    variant="p"
                                    color="secondary"
                                    sx={{
                                      width: "100%",
                                      fontSize: 14,
                                      textAlign: "left",
                                    }}
                                  >
                                    Special Trip
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      width: "100%",
                                      textAlign: "left",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {v?.studentAcademicDetailResponseDto
                                      ?.hasSpecialTrip
                                      ? "Yes"
                                      : "No"}
                                  </Typography>
                                </div>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        {v?.paymentInputOptions?.term1fees ||
                        v?.paymentInputOptions?.term2fees ||
                        v?.paymentInputOptions?.term3fees ||
                        v?.paymentInputOptions?.tuitionFees ||
                        v?.paymentInputOptions?.bookFees ||
                        v?.paymentInputOptions?.twoWayFees ||
                        v?.paymentInputOptions?.specialBusFees ? (
                          <AddPayment
                            button="Add Payment"
                            data={v?.paymentInputOptions}
                            year={
                              v?.studentAcademicDetailResponseDto?.batchYear
                            }
                            currentId={uuid}
                            addPayment={addPayment}
                          ></AddPayment>
                        ) : null}
                        <div style={{ marginTop: 15 }}>
                          <Table3
                            columns={feesPaymentcolumns}
                            rows={v?.feesPaidHistoryList}
                            visibilityIcon={false}
                            editIcon={false}
                            deleteHandler={paymentRecordDeleteHandler}
                          />
                          {/* <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    {feesPaymentcolumns.map((val) => (
                                      <TableCell align="left">{val}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {v?.feesPaymentTableHistory.map((row) => (
                                    <TableRow
                                      key={row}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {row.map((v) => (
                                        <TableCell component="th" scope="row">
                                          {v}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer> */}
                          <Accordion sx={{ marginTop: 2 }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Fees Structure</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {v?.paymentInputOptions?.issues?.length > 0 && (
                                <>
                                  <h3>Issues</h3>
                                  {v?.paymentInputOptions?.issues?.map(
                                    (issue, index) => (
                                      <h4 style={{ color: "red" }}>
                                        {index + 1}: {issue}
                                      </h4>
                                    )
                                  )}
                                </>
                              )}
                              <div style={{ marginTop: 15 }}>
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">
                                          A: Fees
                                        </TableCell>
                                        <TableCell align="left">
                                          B: Discount Fees
                                        </TableCell>
                                        <TableCell align="left">
                                          C: Final Fees(A-B)
                                        </TableCell>
                                        <TableCell align="left">
                                          D: Paid Fees
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Term1 Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.term1FeesFull
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions
                                            ?.term1FeesFull -
                                            v?.paymentInputOptions
                                              ?.term1FeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.term1FeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.term1PaidFee}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Term2 Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.term2FeesFull
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions
                                            ?.term2FeesFull -
                                            v?.paymentInputOptions
                                              ?.term2FeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.term2FeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.term2PaidFee}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Term3 Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.term3FeesFull
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions
                                            ?.term3FeesFull -
                                            v?.paymentInputOptions
                                              ?.term3FeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.term3FeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.term3PaidFee}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Book Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.bookFeesFull}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions
                                            ?.bookFeesFull -
                                            v?.paymentInputOptions
                                              ?.bookFeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.bookFeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.bookPaidFee}
                                        </TableCell>
                                      </TableRow>

                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Lab Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.labFeesFull}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.labFeesFull -
                                            v?.paymentInputOptions
                                              ?.labFeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.labFeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions?.labPaidFee}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Two way Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.twoWayFeesFull
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions
                                            ?.twoWayFeesFull -
                                            v?.paymentInputOptions
                                              ?.twoWayFeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.twoWayFeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.twoWayPaidFee
                                          }
                                        </TableCell>
                                      </TableRow>

                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          Special Trip Fees
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.specialTripFeesFull
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {v?.paymentInputOptions
                                            ?.specialTripFeesFull -
                                            v?.paymentInputOptions
                                              ?.specialTripFeesAmount}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.specialTripFeesAmount
                                          }
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {
                                            v?.paymentInputOptions
                                              ?.specialTripPaidFee
                                          }
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            <div style={{ marginTop: 20 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>More Detail</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Admission Date
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.admissionDate}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Remarks
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.remark || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Admission Number
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.admissionNo || "-"}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Student Sub Caste
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.studentSubCaste || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Blood Group
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.bloodGroup || "-"}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          TC Issued
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.tcIssued || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mobile Number
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.mobileNumber || "-"}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Aadhaar Number
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.adharNumber || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Email ID
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.email || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Previous School
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {studentDetail?.previousSchool || "-"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginTop: 20 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Parent Details and Contact Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Name
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherName}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Aadhaar
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherAadhaar || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Mobile Number
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherMobileNo}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Annual Income
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherAnnualIncome || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Occupation
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherOccupation}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Qualification
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherQualification || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Caste
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherCaste}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Religion
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherReligion}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Father Email
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.fatherEmail || "-"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: "3px" }}>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Name
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherName}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Aadhaar
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherAadhaar || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Mobile Number
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherMobileNo}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Annual Income
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherAnnualIncome || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Occupation
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherOccupation}
                        </Typography>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Qualification
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherQualification || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Caste
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherCaste || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Religion
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherReligion || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Mother Email
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.motherEmail || "-"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: "3px" }}>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Home Mobile Number
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.homeMobileNo}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Address 1
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.address1 || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Address 2
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.address2 || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Place
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.place || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          District
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.district || "-"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            width: "100%",
                            fontSize: 14,
                            textAlign: "left",
                          }}
                        >
                          Pincode
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "100%",
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                        >
                          {parentDetail?.pincode || "-"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
            {siblingDetail !== [] && (
              <div style={{ marginTop: 20 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Sibling Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Student Id</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {siblingDetail?.map((e, index) => (
                            <TableRow
                              component={Link}
                              to={`/studentdetail/${e.uniqueId}`}
                              target="_blank"
                              key={index}
                              sx={{
                                textDecoration: "none",
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {e.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {e.studentId}
                              </TableCell>
                            </TableRow>
                            // </Link>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}

            {studentDetail?.previousUnregisteredYearBalance !== 0 ||
            studentHistoryDetails?.previousFeesPaymentResponseList?.length >
              0 ? (
              <div style={{ marginTop: 20 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Fees Payment till 2021</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container style={{ marginTop: 2 }} spacing={2}>
                      <Grid item xs={2}>
                        <div>
                          <Typography
                            variant="p"
                            color="secondary"
                            sx={{
                              width: "100%",
                              fontSize: 14,
                              textAlign: "left",
                            }}
                          >
                            Previous Fees Payment:
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              width: "100%",
                              textAlign: "left",
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            {studentDetail?.previousUnregisteredYearBalance}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Addprevdata
                      button="Add Previous Payment"
                      data={studentDetail?.previousUnregisteredYearBalance}
                      prevPayment={prevPayment}
                      currentId={uuid}
                    ></Addprevdata>
                    <div style={{ marginTop: 15 }}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Action</TableCell>
                              <TableCell align="left">Amount</TableCell>
                              <TableCell align="left">Paid on</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {studentHistoryDetails?.previousFeesPaymentResponseList?.map(
                              (row) => (
                                <TableRow
                                  key={row}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Button
                                      sx={{
                                        background: "#3d07dc",
                                        marginRight: 2,
                                        padding: 1,
                                      }}
                                      onClick={() => {
                                        deletePreviousFeesRecord(row.uniqueId);
                                      }}
                                    >
                                      <DeleteIcon
                                        sx={{ color: "#fff", fontSize: 17 }}
                                      />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.feesPaid}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.payedOn}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <div></div>
            )}
          </Box>
        </Tablebox>
      </ThemeProvider>
    </>
  );
};

export default ViewStudent;
