import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch.js";
import TabPanel from "../../Components/Tab";
import { theme } from "../../Layouts/Themesetup/index";

import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const bloodGroup = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Editstudent = ({
  studentDetail,
  parentDetail,
  button,
  setEditStudentDetails,
}) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({ dob: null });
  const [admissionDate, setAdmissionDate] = useState({ date: null });

  const [value, setValue] = useState(0);

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
    if (studentDetail && parentDetail) {
      for (const [key, value] of Object.entries(studentDetail)) {
        formik.setFieldValue(key, value);
      }
      for (const [key, value] of Object.entries(parentDetail)) {
        formik.setFieldValue(key, value);
      }
      setDate({ dob: dayjs(studentDetail.dob) });
      setAdmissionDate({ date: dayjs(studentDetail.admissionDate) });
    }
  };
  const handleClose = () => {
    setOpen(false);
    setDate({ dob: null });
    setAdmissionDate({ date: null });
  };

  const updateStudent = (val) => {
    setEditStudentDetails({
      api: () => api.updateStudent(studentDetail.uniqueId, val),
    });
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      admissionNo: "",
      adharNumber: "",
      email: "",
      studentCaste: "",
      studentReligion: "",
      gender: "",
      emisNo: "",
      bloodGroup: "",
      previousSchool: "",
      remark: "",
      fatherName: "",
      motherName: "",
      fatherMobileNo: "",
      motherMobileNo: "",
      homeMobileNo: "",
      fatherOccupation: "",
      motherOccupation: "",
      fatherCaste: "",
      motherCaste: "",
      fatherReligion: "",
      motherReligion: "",
      fatherAnnualIncome: 0,
      motherAnnualIncome: 0,
      fatherAadhaar: "",
      motherAadhaar: "",
      fatherQualification: "",
      motherQualification: "",
      fatherEmail: "",
      motherEmail: "",
      address1: "",
      previousUnregisteredYearBalance: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      admissionNo: Yup.string().required("Required"),
      // dob: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      fatherName: Yup.string().required("Required"),
      motherName: Yup.string().required("Required"),
      fatherMobileNo: Yup.number().required("Required"),
      motherMobileNo: Yup.number().required("Required"),
      address1: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      updateStudent({
        ...values,
        admissionDate: admissionDate.date.format("YYYY-MM-DD"),
        dob: date.dob.format("YYYY-MM-DD"),
      });
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleOpen}
          variant="contained"
          size="medium"
          color="buttoncolor"
          sx={{ color: "#fff" }}
        >
          {button}
        </Button>
      </div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 2, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle id="alert-dialog-title">Edit Basic Details</DialogTitle>
          <DialogContent style={{ paddingTop: 5 }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2} sx={{ overflow: "hidden" }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    name="name"
                    id="name"
                    label="Student Name*"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.name}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    name="admissionNo"
                    id="admissionNo"
                    label="Admission No*"
                    value={formik.values.admissionNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.admissionNo && formik.errors.admissionNo ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.admissionNo}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    type="number"
                    name="mobileNumber"
                    label="Mobile Number"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    type="number"
                    id="adharNumber"
                    label="Aadhaar Number"
                    value={formik.values.adharNumber}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 12);
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.adharNumber && formik.errors.adharNumber ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.adharNumber}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    type="email"
                    id="email"
                    label="Email Id"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="dob"
                      label="DOB*"
                      inputFormat="DD-MM-YYYY"
                      value={date.dob}
                      onChange={(newValue) => {
                        setDate({ dob: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="admissionDate"
                      label="Admission Date*"
                      inputFormat="DD-MM-YYYY"
                      value={admissionDate.date}
                      onChange={(newValue) => {
                        setAdmissionDate({ date: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    id="studentCaste"
                    label="Student Caste"
                    value={formik.values.studentCaste}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.studentCaste && formik.errors.studentCaste ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.studentCaste}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    id="studentReligion"
                    label="Student Religion"
                    value={formik.values.studentReligion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.studentReligion &&
                  formik.errors.studentReligion ? (
                    <div style={{ marginLeft: "15px", color: "red" }}>
                      {formik.errors.studentReligion}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Gender*
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="MALE"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="FEMALE"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="OTHER"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    label="Emis No"
                    name="emisNo"
                    value={formik.values.emisNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ margin: "0", width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Blood Group
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="bloodGroup"
                      name="bloodGroup"
                      value={formik.values.bloodGroup}
                      onChange={formik.handleChange}
                    >
                      {bloodGroup?.map((ye, key) => {
                        return (
                          <MenuItem key={key} value={ye}>
                            {ye}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    label="Previous School"
                    name="previousSchool"
                    value={formik.values.previousSchool}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    label="Remark"
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    label="Fees Till 2021"
                    name="previousUnregisteredYearBalance"
                    value={formik.values.previousUnregisteredYearBalance}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{ borderBottom: 1, borderColor: "divider", marginTop: 2 }}
                >
                  <Tabs
                    value={value}
                    onChange={handleTab}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Parent Details" {...a11yProps(0)} />
                    <Tab label="Contact Details" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      overflow: "hidden",
                      "& .css-19kzrtu": { padding: "0" },
                    }}
                  >
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherName"
                        label="Father Name*"
                        value={formik.values.fatherName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fatherName && formik.errors.fatherName ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.fatherName}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherOccupation"
                        label="Father Occupation"
                        value={formik.values.fatherOccupation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fatherOccupation &&
                      formik.errors.fatherOccupation ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.fatherOccupation}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherMobileNo"
                        label="Father Mobile No*"
                        value={formik.values.fatherMobileNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fatherMobileNo &&
                      formik.errors.fatherMobileNo ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.fatherMobileNo}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherCaste"
                        label="Father Caste"
                        value={formik.values.fatherCaste}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fatherCaste &&
                      formik.errors.fatherCaste ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.fatherCaste}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherReligion"
                        label="Father Religion"
                        value={formik.values.fatherReligion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fatherReligion &&
                      formik.errors.fatherReligion ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.fatherReligion}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherAnnualIncome"
                        label="Father Annual Income"
                        value={formik.values.fatherAnnualIncome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherAadhaar"
                        label="Father Aadhaar"
                        value={formik.values.fatherAadhaar}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherQualification"
                        label="Father Qualification"
                        value={formik.values.fatherQualification}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="fatherEmail"
                        label="Father Email"
                        value={formik.values.fatherEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherName"
                        label="Mother Name*"
                        value={formik.values.motherName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.motherName && formik.errors.motherName ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.motherName}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherOccupation"
                        label="Mother Occupation"
                        value={formik.values.motherOccupation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.motherOccupation &&
                      formik.errors.motherOccupation ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.motherOccupation}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherMobileNo"
                        label="Mother Mobile No*"
                        value={formik.values.motherMobileNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.motherMobileNo &&
                      formik.errors.motherMobileNo ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.motherMobileNo}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherCaste"
                        label="Mother Caste"
                        value={formik.values.motherCaste}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.motherCaste &&
                      formik.errors.motherCaste ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.motherCaste}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherReligion"
                        label="Mother Religion"
                        value={formik.values.motherReligion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.motherReligion &&
                      formik.errors.motherReligion ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.motherReligion}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherAnnualIncome"
                        label="Mother Annual Income"
                        value={formik.values.motherAnnualIncome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherAadhaar"
                        label="Mother Aadhaar"
                        value={formik.values.motherAadhaar}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherQualification"
                        label="Mother Qualification"
                        value={formik.values.motherQualification}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="motherEmail"
                        label="Mother Email"
                        value={formik.values.motherEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      overflow: "hidden",
                      "& .css-19kzrtu": { padding: "0" },
                    }}
                  >
                    <Grid item xs={12} md={4}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="homeMobileNo"
                        label="Home Mobile No"
                        value={formik.values.homeMobileNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.homeMobileNo &&
                      formik.errors.homeMobileNo ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.homeMobileNo}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        sx={{
                          "&.MuiTextField-root": {
                            margin: "0",
                            width: "100%",
                          },
                        }}
                        name="address1"
                        label="Address Line 1*"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.address1 && formik.errors.address1 ? (
                        <div style={{ marginLeft: "15px", color: "red" }}>
                          {formik.errors.address1}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                </TabPanel>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              type="submit"
              disabled={
                !formik.values.name ||
                !formik.values.admissionNo ||
                !admissionDate ||
                !date ||
                !formik.values.gender ||
                !formik.values.fatherName ||
                !formik.values.motherName ||
                !formik.values.fatherMobileNo ||
                !formik.values.motherMobileNo ||
                !formik.values.address1
                  ? "disabled"
                  : ""
              }
              size="large"
              variant="contained"
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default Editstudent;
