import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Modal from "../../Components/Modal/index";
import Table3 from "../../Components/Table/Table3";
import Adddata from "./Addyear";
import Promote from "./Promote";

import EditYear from "./EditYear";

const columns = [{ id: "batchYear", label: "Batch", minWidth: 170 }];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const Bus = () => {
  const [yearData, setYearData] = useFetch({ data: [], total: 0 });

  const [pagination, setPagination] = useState({ page: 0, size: 10 });

  const [promoteData, setPromote] = useFetch();

  const [isEdit, setIsEdit] = useState(false);
  const [editYear, setEditYear] = useState("");
  const [open, setOpen] = useState(false);
  const [alertval, setAlertval] = useState();

  const fetchBatchYear = () => {
    setYearData({
      api: () => api.fetchYear(pagination),
      res: (data) => ({
        data: data.data.academicYearDetailsDtoListList,
        total: data.data.total,
      }),
    });
  };

  const addBatchYear = (year) => {
    setYearData({
      api: () => api.newYear({ batchYear: year }),
      res: (data) => {
        return {
          data: [...yearData.data, { batchYear: data.data.batchYear }],
          total: yearData.total + 1,
        };
      },
    });
  };

  const promote = (values) => {
    setPromote({
      api: () => api.promote(values.from, values.to),
    });
  };

  const updateBatchYear = (val1, val2) => {
    setYearData({
      api: () => api.updateYear(val1, val2),
      res: (data) => {
        const batchYearList = yearData.data.map((eachYear, index) => {
          if (eachYear.batchYear === val1) return { batchYear: val2 };
          return eachYear;
        });

        return { data: batchYearList, total: yearData.total };
      },
    });
  };

  const deleteBatchYear = (year) => {
    setYearData({
      api: () => api.deleteYear(year.batchYear),
      res: (data) => {
        const batchYearList = yearData.data
          .filter((eachYear) => eachYear.batchYear !== year.batchYear)
          .map((eachYear) => eachYear);
        return { data: batchYearList, total: yearData.total - 1 };
      },
    });
    fetchBatchYear();
  };

  const handleChangePage = (e, val) => {
    setPagination({ ...pagination, page: val });
  };

  const handleChangeRowsPerPage = (e, val) => {
    setPagination({ ...pagination, size: e.target.value });
  };

  const openYearEdit = (year) => {
    setIsEdit(true);
    setEditYear(year.batchYear);
  };

  useEffect(() => {
    fetchBatchYear();
  }, []);

  return (
    <>
      <Modal open={open} setOpen={setOpen} alertval={alertval}></Modal>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Batch Year
          </Typography>
          <Adddata
            button="Add Batch Year"
            addBatchYear={addBatchYear}
          ></Adddata>
          <Promote
            button="Promote"
            promoteFun={promote}
            yearData={yearData}
          ></Promote>
          {isEdit && (
            <EditYear
              setIsEdit={setIsEdit}
              batchYear={editYear}
              updateBatchYear={updateBatchYear}
            />
          )}
        </Box>

        <Table3
          columns={columns}
          rows={yearData.data}
          totalSize={yearData.total}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          pagination={pagination}
          openEditModel={openYearEdit}
          deleteHandler={deleteBatchYear}
          visibilityIcon={false}
        />
      </Tablebox>
    </>
  );
};

export default Bus;
