import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch.js";
import { theme } from "../../Layouts/Themesetup/index";

const Editbatch = ({
  button,
  batchDetail,
  setEditBatchDetails,
  discountList,
  classNameList,
  uuid,
}) => {
  const [open, setOpen] = useState(false);
  const [busStateList, setBusState] = useFetch({ data: [] });
  const [userStateList, setUserState] = useFetch({ data: [] });
  const [section, setSection] = useState(null);
  const [busRouteIndex, setBusRouteIndex] = useState(null);
  const [discountName, setDiscountName] = useState();
  const [classIndex, setClassIndex] = useState(null);

  const getAllBusFees = () => {
    setBusState({
      api: () => api.fetchBusfees(batchDetail.batchYear),
      res: (data) => ({
        data: data.data.busFeesList,
      }),
    });
  };

  const findAllUser = () => {
    setUserState({
      api: () =>
        api.findAllUser({
          userRole: null,
          size: 30,
          page: 0,
        }),
      res: (data) => ({ data: data.data.userList }),
    });
  };

  useEffect(() => {
    getAllBusFees();
    findAllUser();
  }, []);

  const handleOpen = () => {
    setOpen(true);
    if (batchDetail) {
      formik.setFieldValue("sectionName", batchDetail.sectionName);
      formik.setFieldValue("groupId", batchDetail.groupId);
      formik.setFieldValue("busNumber", batchDetail.busNumber);
      formik.setFieldValue("specialTrip", batchDetail.hasSpecialTrip);
      formik.setFieldValue("directorDiscount", batchDetail.directorDiscount);
      formik.setFieldValue("discountReason", batchDetail.discountReason);

      formik.setFieldValue(
        "directorBusDiscount",
        batchDetail.directorBusDiscount
      );
      formik.setFieldValue("className", batchDetail.className);
      setDiscountName(batchDetail.discountName);

      const claas = classNameList?.data?.filter(
        (cn, key) => cn.className === batchDetail?.className
      );
      var classIn = classNameList?.data?.indexOf(claas[0]);
      setClassIndex(classIn);
      setSection(claas[0].sectionName);

      const buno = busStateList?.data?.filter(
        (cn, key) => cn.routeName === batchDetail?.routeName
      );
      const buinde = busStateList?.data.findIndex(
        (cn) => cn.routeName === batchDetail?.routeName
      );
      setBusRouteIndex(buinde);
      formik.setFieldValue("busUuid", buno[0].uniqueId);

      const discount = discountList.data.find(
        (d) => d.discountName === batchDetail?.discountName
      );

      formik.setFieldValue("discountUuid", discount.uniqueId);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const updateBatch = (val) => {
    setEditBatchDetails({
      api: () => api.updateAcademic(batchDetail.batchYear, uuid, val),
    });
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      sectionName: null,
      groupId: null,
      busUuid: null,
      busNumber: null,
      specialTrip: false,
      discountUuid: null,
      directorDiscount: 0,
      directorBusDiscount: 0,
      discountReason: null,
      className: null,
    },
    onSubmit: (values) => {
      updateBatch(values);
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleOpen}
          variant="contained"
          size="medium"
          color="buttoncolor"
          sx={{ color: "#fff" }}
        >
          {button}
        </Button>
      </div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 2, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle id="alert-dialog-title">Edit Batch Details</DialogTitle>
          <DialogContent style={{ paddingTop: 5 }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2} sx={{ overflow: "hidden" }}>
                {formik?.values?.className?.includes("XI-") && (
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ margin: "0", width: "100%" }}>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Class Name
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="className"
                        value={formik.values.className}
                        label="Class Name"
                        name="className"
                        onChange={formik.handleChange}
                      >
                        {classNameList?.data
                          .filter((value, key) =>
                            value.className.includes("XI-")
                          )
                          .map((ye, key) => {
                            return (
                              <MenuItem
                                key={key}
                                onClick={() => setClassIndex(key)}
                                value={ye.className}
                              >
                                {ye.className}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ margin: "0", width: "100%" }}>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Section Name
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="sectionName"
                      value={formik.values.sectionName}
                      label="Section Name"
                      name="sectionName"
                      onChange={formik.handleChange}
                    >
                      {classNameList.data[classIndex]?.sectionName
                        ?.split(",")
                        ?.map((secName) => {
                          return <MenuItem value={secName}>{secName}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    id="groupId"
                    label="Group Id"
                    name="groupId"
                    value={formik.values.groupId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ margin: "0", width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Bus Route
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="busUuid"
                      label="Bus Route"
                      name="busUuid"
                      value={formik.values.busUuid}
                      onChange={formik.handleChange}
                    >
                      {busStateList?.data.map((bf, key) => {
                        return (
                          <MenuItem
                            key={key}
                            onClick={() => setBusRouteIndex(key)}
                            value={bf.uniqueId}
                          >
                            {bf.routeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ margin: "0", width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Bus No
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="busNumber"
                      label="Bus Number"
                      name="busNumber"
                      value={formik.values.busNumber}
                      onChange={formik.handleChange}
                    >
                      {busRouteIndex !== null
                        ? busStateList?.data[busRouteIndex]?.busNumber?.map(
                            (busNum) => {
                              return (
                                <MenuItem value={busNum}>{busNum}</MenuItem>
                              );
                            }
                          )
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    sx={{ margin: "0", marginTop: 1, width: "100%" }}
                    control={
                      <Checkbox
                        checked={formik.values.specialTrip}
                        name="specialTrip"
                        id="specialTrip"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Special Trip"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ margin: "0", width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Discount
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Discount"
                      name="discountUuid"
                      value={formik.values.discountUuid}
                      onChange={formik.handleChange}
                    >
                      {discountList?.data.map((discount, key) => {
                        return (
                          <MenuItem key={key} value={discount.uniqueId}>
                            {discount.discountName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    type="number"
                    name="directorDiscount"
                    label="Director Provided Discount"
                    value={formik.values.directorDiscount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    type="number"
                    name="directorBusDiscount"
                    label="Director Bus Discount"
                    value={formik.values.directorBusDiscount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    sx={{
                      "&.MuiTextField-root": {
                        margin: "0",
                        width: "100%",
                      },
                    }}
                    name="discountReason"
                    label="Discount Reason"
                    value={formik.values.discountReason}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
            <Button onClick={handleClose}>Close</Button>
            <Button type="submit" size="large" variant="contained" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default Editbatch;
