import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch.js";
import Table3 from "../../Components/Table/Table3";
import EditClass from "./EditClass.js";

const columns = [
  { id: "className", align: "center", label: "Class", minWidth: 100 },
  { id: "sectionName", align: "center", label: "Section", minWidth: 100 },
];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const ClassSection = () => {
  const [classSectionList, setClassSection] = useFetch({ data: [] });

  const [isEdit, setIsEdit] = useState(false);

  const [editClassSection, setEditClassSection] = useState(null);

  const findAllClass = () => {
    setClassSection({
      api: () => api.fetchClass(),
      res: (data) => ({ data: data.data.classSectionList }),
    });
  };

  const updateSection = (val) => {
    setClassSection({
      api: () => api.updateSection(val.className, val.sectionName),
      res: (data) => {
        const finalClassSection = classSectionList.data.map((classSection) => {
          if (classSection.className === val.className)
            return { ...classSection, sectionName: val.sectionName.join() };
          else return classSection;
        });
        return { data: finalClassSection };
      },
    });
  };

  const openClassSectionEdit = (classSection) => {
    setIsEdit(true);
    setEditClassSection(classSection);
  };
  useEffect(() => {
    findAllClass();
  }, []);

  return (
    <>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Class
          </Typography>
          {isEdit && (
            <EditClass
              updateSection={updateSection}
              setIsEdit={setIsEdit}
              editSection={editClassSection}
            />
          )}
        </Box>

        <Table3
          columns={columns}
          rows={classSectionList.data}
          visibilityIcon={false}
          deleteIcon={false}
          openEditModel={openClassSectionEdit}
        />
      </Tablebox>
    </>
  );
};

export default ClassSection;
