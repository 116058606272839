import { FileUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Table3 from "../../Components/Table/Table3";
import AddAcademicFees from "./Addfees";
import EditAcademicFees from "./EditFees";

const columns = [
  { id: "className", align: "center", label: "Class Name", minWidth: 100 },
  {
    id: "bookFees",
    align: "center",
    label: "Book Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "term1",
    align: "center",
    label: "Term 1",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "term2",
    align: "center",
    label: "Term 2",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "term3",
    align: "center",
    label: "Term 3",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "labFees",
    align: "center",
    label: "Lab Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "totalFees",
    align: "center",
    label: "Total Fees",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-IN"),
  },
];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const AcademicFees = () => {
  const [academicFeesList, setAcademicFeesList] = useFetch({ data: [] });
  const [classNameState, setClassNameState] = useFetch({ data: [] });
  const [exportFile, setExportFile] = useFetch(null);
  const [yearData, setYearData] = useFetch({ data: [], total: 0 });

  const [pagination, setPagination] = useState({ page: 0, size: 100 });
  const [currentBatch, setCurrentBatch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editAcademicFee, setEditAcademicFee] = useState(null);
  const fileExportRef = useRef(null);

  const fetchClassSection = () => {
    setClassNameState({
      api: () => api.fetchClass(),
      res: (data) => ({ data: data.data.classSectionList }),
    });
  };

  const fetchBatchYear = () => {
    setYearData({
      api: () => api.fetchYear(pagination),
      res: (data) => ({
        data: data.data.academicYearDetailsDtoListList,
        total: data.data.total,
      }),
    });
  };

  const getFees = (year) => {
    setAcademicFeesList({
      api: () => api.fetchFees(year),
      res: (data) => ({ data: data.data.tuitionFeesList }),
    });
  };

  const postFees = (newfees) => {
    setAcademicFeesList({
      api: () => api.createFees({ ...newfees, year: currentBatch }),
      res: (data) => ({ data: [...academicFeesList.data, data.data] }),
    });
  };

  const updateFees = (val) => {
    setAcademicFeesList({
      api: () => api.updateFees(val.className, val.year, val),
      res: (data) => {
        const finalFeesList = academicFeesList.data.map((fees) => {
          if (fees.className === val.className) return data.data;
          return fees;
        });
        return { data: finalFeesList };
      },
    });
  };

  const deleteFees = (val) => {
    setAcademicFeesList({
      api: () => api.deleteFees(val.className, val.year),
      res: (data) => {
        const finalFeesList = academicFeesList.data.filter(
          (fee) => fee.className !== val.className
        );
        return { data: finalFeesList };
      },
    });
  };

  const handleExportFile = () => {
    setExportFile({
      api: () => api.exportTuitionFees(currentBatch),
      res: (data) => ({ fileUuid: data.data }),
    });
  };

  const openAcademicFeeEdit = (busFee) => {
    setIsEdit(true);
    setEditAcademicFee(busFee);
  };

  useEffect(() => {
    fetchBatchYear();
  }, []);

  useEffect(() => {
    if (yearData.total !== 0) {
      const year = yearData.data[0].batchYear;
      setCurrentBatch(year);
      fetchClassSection();
    }
  }, [yearData]);

  useEffect(()=>{
    if(currentBatch!==""){
      getFees(currentBatch);
    }
  },[currentBatch])
  

  useEffect(() => {
    if (exportFile != null) {
      fileExportRef.current.click();
    }
  }, [exportFile]);

  return (
    <>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Academic Fees
          </Typography>
          <a
            href={`https://svsschool.co.in/services/svs/api/tuitionFees/exportFile/${exportFile?.fileUuid}`}
            ref={fileExportRef}
            target="_blank"
            rel="noreferrer"
            style={{ display: "none" }}
          >
            -
          </a>

          <FormControl sx={{ minWidth: "150px" }}>
            <InputLabel id="demo-simple-select-label-batch">Batch</InputLabel>
            <Select
              labelId="demo-simple-select-label-batch"
              id="demo-simple-select-batch"
              value={currentBatch}
              label="Age"
              onChange={(event) => setCurrentBatch(event.target.value)}
            >
              {yearData?.data.map((ye, key) => {
                return (
                  <MenuItem key={key} value={ye.batchYear}>
                    {ye.batchYear}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div style={{ display: "flex", gap: 15 }}>
            <Button
              variant="outlined"
              onClick={handleExportFile}
              color="primary"
            >
              {<FileUpload />} Export
            </Button>
            <AddAcademicFees
              postFees={postFees}
              classNameState={classNameState}
            />
          </div>
          {isEdit && (
            <EditAcademicFees
              setIsEdit={setIsEdit}
              editAcademicFee={editAcademicFee}
              updateAcademicfees={updateFees}
              classNameState={classNameState}
            />
          )}
        </Box>
        <Table3
          columns={columns}
          rows={academicFeesList.data}
          openEditModel={openAcademicFeeEdit}
          deleteHandler={deleteFees}
          visibilityIcon={false}
        />
      </Tablebox>
    </>
  );
};

export default AcademicFees;
