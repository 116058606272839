import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Table3 from "../../Components/Table/Table3";
import AddUser from "./AddUser";

const columns = [
  { id: "userName", align: "center", label: "User Name", minWidth: 100 },
  { id: "email", align: "center", label: "Email", minWidth: 100 },
  { id: "userRole", align: "center", label: "Role", minWidth: 100 },
];

const Tablebox = styled("div")(({ theme }) => ({
  marginTop: 80,
  marginLeft: 80,
  padding: theme.spacing(0, 3),
}));

const User = () => {
  const [pagination, setPagination] = useState({ page: 0, size: 10 });

  const [roles, setRoles] = useFetch({ data: [] });
  const [userList, setUser] = useFetch({ data: [], size: 0 });
  const [currentRole, setCurrentRole] = useState("");
  const getRoles = () => {
    setRoles({
      api: () => api.getRoles(),
      res: (data) => ({ data: data.data }),
    });
  };

  const findAllUser = (role) => {
    const requestBody = {
      userRole: role,
      size: pagination.size,
      page: pagination.page,
    };
    setUser({
      api: () => api.findAllUser(requestBody),
      res: (data) => ({ data: data.data.userList, size: data.data.size }),
    });
  };

  const addUser = (val) => {
    val.userRole = currentRole;
    setUser({
      api: () => api.addUser(val),
      res: (data) => ({
        data: [...userList.data, val],
        size: userList.size + 1,
      }),
    });
  };

  const handleChangePage = (e, val) => {
    setPagination({ ...pagination, page: val });
  };

  const handleChangeRowsPerPage = (e, val) => {
    setPagination({ ...pagination, size: e.target.value });
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    setCurrentRole(roles.data[0]);
    findAllUser(roles.data[0]);
  }, [roles]);

  return (
    <>
      <Tablebox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Users
          </Typography>
          <FormControl sx={{ minWidth: "150px" }}>
            <InputLabel id="demo-simple-select-label-role">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label-role"
              id="demo-simple-select-role"
              value={currentRole}
              label="Age"
              onChange={(event) => {
                setCurrentRole(event.target.value);
                findAllUser(event.target.value);
              }}
            >
              {roles?.data.map((role, key) => {
                return (
                  <MenuItem key={key} value={role}>
                    {role}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <AddUser userRole={roles.data} postUser={addUser}></AddUser>
        </Box>
        <Table3
          columns={columns}
          rows={userList.data}
          totalSize={userList.size}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          pagination={pagination}
          visibilityIcon={false}
          deleteIcon={false}
          editIcon={false}
        />
      </Tablebox>
    </>
  );
};

export default User;
