export const FETCH = "FETCH_ALL";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const LOADING = "LOADING";
export const TOAST = "TOAST";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const LOGGEDIN = "LOGGEDIN";
export const UNAUTHORIZED = "UNAUTHORIZED";

export const FETCHYEAR = "FETCH_YEAR";
export const CREATEYEAR = "CREATE_YEAR";
export const UPDATEYEAR = "UPDATE_YEAR";
export const DELETEYEAR = "DELETE_YEAR";
export const ERROR = "ERROR";

export const CREATECLASS = "CREATE_CLASS";
export const FETCHCLASS = "FETCH_CLASS";
export const UPDATESECTION = "UPDATE_SECTION";

export const CREATEDISCOUNT = "CREATE_DISCOUNT";
export const FETCHDISCOUNT = "FETCH_DISCOUNT";
export const UPDATEDISCOUNT = "UPDATE_DISCOUNT";
export const DELETEDISCOUNT = "DELETE_DISCOUNT";

export const CREATEFEES = "CREATE_FEES";
export const FETCHFEES = "FETCH_FEES";
export const UPDATEFEES = "UPDATE_FEES";
export const DELETEFEES = "DELETE_FEES";

export const CREATESTUDENT = "CREATE_STUDENT";
export const FETCHSTUDENT = "FETCH_STUDENT";
export const IMPORTSTUDENT = "IMPORT_STUDENT";
export const EXPORTSTUDENT = "EXPORT_STUDENT";
export const UPDATESTUDENT = "UPDATE_STUDENT";
export const UPDATEACADEMIC = "UPDATE_ACADEMIC";

export const VIEWSTUDENT = "VIEW_STUDENT";
export const PAYMENT = "PAYMENT";
export const PREVPAYMENT = "PREVPAYMENT";

export const GETROLES = "GET_ROLES";
export const ADDUSER = "ADD_USER";
export const FINDALLUSER = "FIND_ALL_USER";
export const GETDIRECTORS = "GET_DIRECTORS";

export const LOADER_LOADING = "LOADER_LOADING";
export const LODING_COMPLETED = "LOADING_COMPLETED";
