import {
  AccountBalanceWallet,
  ArrowDropDown,
  ArrowDropUp,
  AssignmentInd,
  DirectionsBus,
  Discount,
  PersonAddAlt,
  School,
  Settings,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Loader from "../../Components/Loader/loader.js";
import { LOGOUT } from "../../constants/actionTypes.js";
import { theme } from "../Themesetup";
import "./style.css";

const drawerWidth = 240;

const menus = [
  {
    id: "dashboard",
    icon: DashboardIcon,
    text: "Dashboard",
    path: "/dashboard",
  },
  {
    id: "paymentHistory",
    icon: HistoryIcon,
    text: "Payment History",
    path: "/paymentHistory",
  },
  {
    id: "student",
    icon: AssignmentInd,
    text: "Student",
    path: "/student",
  },
  {
    id: "setting",
    icon: Settings,
    text: "Setting",
    submenus: [
      {
        id: "academicyear",
        icon: EventIcon,
        text: "Academic Year",
        path: "/academicyear",
      },
      {
        id: "busfees",
        icon: DirectionsBus,
        text: "Bus Fees",
        path: "/busfees",
      },
      {
        id: "class",
        icon: School,
        text: "Class",
        path: "/class",
      },
      {
        id: "discount",
        icon: Discount,
        text: "Discount",
        path: "/discount",
      },
      {
        id: "academicfees",
        icon: AccountBalanceWallet,
        text: "Academic Fees",
        path: "/academic_fees",
      },
      {
        id: "user",
        icon: PersonAddAlt,
        text: "Users",
        path: "/user",
      },
    ],
  },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - 80px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ auth }) {
  const [showLoader, setShowloader] = useState(false);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selectid, setSelectid] = useState("");
  const [submenu, setSubmenu] = useState(false);

  const [logOut, setLogOut] = useFetch(null);
  const handleLogout = () => {
    setLogOut({
      api: () => api.signout(),
      dispatchType: (data) => ({ type: LOGOUT }),
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const selectedMenu = (id) => {
    setSelectid(id);
    setOpen(false);
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case "/dashboard":
        return setSelectid("dashboard");
      case "/student":
        return setSelectid("student");
      case "/academicyear":
        return setSelectid("academicyear");
      case "/busfees":
        return setSelectid("busfees");
      case "/class":
        return setSelectid("class");
      case "/discount":
        return setSelectid("discount");
      case "/academic_fees":
        return setSelectid("academicfees");
      case "/user":
        return setSelectid("user");
      case "/paymentHistory":
        return setSelectid("paymentHistory");
      default:
        return setSelectid("student");
    }
  }, [selectid]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {showLoader && <Loader></Loader>}
        <AppBar
          position="fixed"
          open={open}
          sx={{ boxShadow: "0px 2px 10px #0000001c", background: "#fff" }}
        >
          <Toolbar sx={{ "&.MuiToolbar-root": { paddingLeft: 4 } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="h6"
              sx={{ width: "100%", color: "#1C74F2" }}
            >
              SVS School
            </Typography>
            <div
              style={{
                width: "100%",
                textAlign: "end",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="p"
                noWrap
                component="p"
                sx={{ width: "100%", marginRight: 2, color: "#000" }}
              >
                Welcome, Admin
              </Typography>
              <Button
                variant="outlined"
                sx={{ color: "#1C74F2", borderColor: "#1C74F2" }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          onMouseOver={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paper": {
              background: "#fff",
              color: "#000",
              paddingRight: 1,
              paddingLeft: 1,
              zIndex: 1299,
            },
          }}
        >
          <DrawerHeader sx={{ justifyContent: "flex-start" }}>
            <IconButton sx={{ color: "#000", marginRight: 3 }}>
              {!open ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="h6"
              sx={{ width: "100%", color: "#1C74F2" }}
            >
              SVS School
            </Typography>
          </DrawerHeader>
          <List sx={{ marginTop: 2 }}>
            {menus.map((menu, index) => {
              return (
                <>
                  {menu.path && (
                    <ListItem
                      onClick={() => selectedMenu(menu.id)}
                      className={selectid === menu.id ? "active" : ""}
                      key={menu.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <Link to={menu.path} style={{ textDecoration: "none" }}>
                        <ListItemButton
                          sx={{
                            minHeight: 70,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginBottom: 2,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "#000",
                            }}
                          >
                            {<menu.icon />}
                          </ListItemIcon>
                          <ListItemText
                            primary={menu.text}
                            sx={{
                              opacity: open ? 1 : 0,
                              color: "#000",
                              textDecoration: "none",
                            }}
                          />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  )}
                  {menu.id === "setting" && (
                    <>
                      <ListItem
                        onClick={() => setSubmenu(!submenu)}
                        key={menu.id}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 70,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            marginBottom: 2,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: "#000",
                            }}
                          >
                            {<menu.icon />}
                          </ListItemIcon>
                          {!open && (
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                                color: "#000",
                              }}
                            >
                              {submenu ? <ArrowDropUp /> : <ArrowDropDown />}
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={menu.text}
                            sx={{
                              opacity: open ? 1 : 0,
                              color: "#000",
                              textDecoration: "none",
                            }}
                          />
                          {open && (
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                                color: "#000",
                              }}
                            >
                              {submenu ? <ArrowDropUp /> : <ArrowDropDown />}
                            </ListItemIcon>
                          )}
                        </ListItemButton>
                      </ListItem>
                      {submenu && (
                        <>
                          {menu.submenus.map((subm, index) => {
                            return (
                              <>
                                <ListItem
                                  onClick={() => selectedMenu(subm.id)}
                                  className={
                                    selectid === subm.id ? "active" : ""
                                  }
                                  key={subm.id}
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <Link
                                    to={subm.path}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <ListItemButton
                                      sx={{
                                        minHeight: 70,
                                        justifyContent: open
                                          ? "initial"
                                          : "center",
                                        px: 2.5,
                                        marginBottom: 2,
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 3 : "auto",
                                          justifyContent: "center",
                                          color: "#000",
                                        }}
                                      >
                                        {<subm.icon />}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={subm.text}
                                        sx={{
                                          opacity: open ? 1 : 0,
                                          color: "#000",
                                          textDecoration: "none",
                                        }}
                                      />
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </List>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}
