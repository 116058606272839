import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";

const Button = styled("button")(({ theme }) => ({
  padding: 10,
  color: theme.primary,
  borderRadius: 8,
  border: 0,
}));

const Table3 = ({
  columns,
  rows,
  totalSize,
  handleChangePage,
  handleChangeRowsPerPage,
  pagination = false,
  visibilityIcon = true,
  editIcon = true,
  deleteIcon = true,
  openEditModel,
  deleteHandler,
}) => {
  const getValue = (column, value) => {
    if (column.format && typeof value === "number") return column.format(value);

    if (typeof value === "string" && !value) return "-";

    if (typeof value === "boolean") return value ? "Yes" : "No";

    return value;
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 680, paddingBottom: 5 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {(visibilityIcon || editIcon || deleteIcon) && (
                <TableCell>Action</TableCell>
              )}

              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {(visibilityIcon || editIcon || deleteIcon) && (
                    <TableCell>
                      <>
                        {visibilityIcon && (
                          <Button
                            sx={{
                              background: "#3d07dc",
                              marginRight: 2,
                              padding: 1,
                            }}
                          >
                            <VisibilityIcon
                              sx={{ color: "#fff", fontSize: 17 }}
                            />
                          </Button>
                        )}
                        {editIcon && (
                          <Button
                            sx={{
                              background: "#3d07dc",
                              marginRight: 2,
                              padding: 1,
                            }}
                            onClick={() => {
                              openEditModel(row);
                            }}
                          >
                            <EditIcon sx={{ color: "#fff", fontSize: 17 }} />
                          </Button>
                        )}
                        {deleteIcon && (
                          <Button
                            sx={{
                              background: "#3d07dc",
                              marginRight: 2,
                              padding: 1,
                            }}
                            onClick={() => {
                              deleteHandler(row);
                            }}
                          >
                            <DeleteIcon sx={{ color: "#fff", fontSize: 17 }} />
                          </Button>
                        )}
                      </>
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    const value = column.calculate
                      ? column.calculate(row)
                      : row[column.id];

                    return (
                      <TableCell key={column.label} align={column.align}>
                        {getValue(column, value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalSize}
          rowsPerPage={pagination.size}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default Table3;
