import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { theme } from "../../Layouts/Themesetup/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const AddBus = ({ postBusfees, currentBatch }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      routeName: "",
      busNumber: "",
      twoWayFees: "",
      specialTrip: "",
      totalFees: 0,
      batchYear: "",
    },
    validationSchema: Yup.object({
      routeName: Yup.string().required("Required"),
      twoWayFees: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      postBusfees({
        ...values,
        busNumber: values.busNumber.split(","),
        batchYear: currentBatch,
      });

      handleClose();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleOpen}
          variant="contained"
          size="medium"
          color="buttoncolor"
          sx={{ color: "#fff" }}
        >
          Add busFees
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 2, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                <TextField
                sx={{
                  "&.MuiTextField-root": {
                    margin: "15px",
                    marginBottom: 0,
                    width: "100%",
                  },
                }}
                  required
                  id="routeName"
                  label="Route Name"
                  name="routeName"
                  value={formik.values.routeName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                sx={{
                  "&.MuiTextField-root": {
                    margin: "15px",
                    marginBottom: 0,
                    width: "100%",
                  },
                }}
                  required
                  id="busNumber"
                  label="Bus No"
                  name="busNumber"
                  value={formik.values.busNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                sx={{
                  "&.MuiTextField-root": {
                    margin: "15px",
                    marginBottom: 0,
                    width: "100%",
                  },
                }}
                  id="twoWayFees"
                  label="Regular Fees*"
                  type="number"
                  name="twoWayFees"
                  value={formik.values.twoWayFees}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                sx={{
                  "&.MuiTextField-root": {
                    margin: "15px",
                    marginBottom: 0,
                    width: "100%",
                  },
                }}
                  id="specialTrip"
                  label="Special Trip Fees"
                  type="number"
                  name="specialTrip"
                  value={formik.values.specialTrip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                sx={{
                  "&.MuiTextField-root": {
                    margin: "15px",
                    marginBottom: 0,
                    width: "100%",
                  },
                }}
                  id="totalFees"
                  label="Total Fees"
                  type="number"
                  name="totalFees"
                  value={formik.values.twoWayFees + formik.values.specialTrip}
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                </Grid>
              </Grid>
              <div
                 style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 30,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={
                    !formik.values.routeName || !formik.values.twoWayFees
                      ? "disabled"
                      : ""
                  }
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default AddBus;
