import { LOADER_LOADING, LODING_COMPLETED } from "../constants/actionTypes";

const loader = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case LOADER_LOADING:
      return { isLoading: true };
    case LODING_COMPLETED:
      return { isLoading: false };
    default:
      return state;
  }
};

export default loader;
