import React from 'react';
import {Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Viewtypo = ({label, text}) => {
  return (
    <div style={{ marginTop: 20 }}>
    <Typography
      variant="p"
      color="secondary"
      sx={{ width: "100%", fontSize: 14, textAlign: "left" }}
    >
      {label}
    </Typography>
    <Typography
      variant="h6"
      sx={{
        width: "100%",
        textAlign: "left",
        fontSize: 16,
        fontWeight: 500,
      }}
    >
      {text || "-"}
    </Typography>
  </div>
  )
}

export const ViewGrid = ({label1, text1, label2, text2, label3, text3}) => {
    return (
        <Grid item xs={2} >
            <Viewtypo label={label1} text={text1}/>
            <Viewtypo label={label2} text={text2}/>
            <Viewtypo label={label3} text={text3}/>
        </Grid>
    )
  }

// export const AccordionUse = ({title, label1, text1, label2, text2, label3, text3}) => {
//     return (
//         <div style={{ marginTop: 30 }}>
//               <Accordion>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel1a-content"
//                   id="panel1a-header"
//                 >
//                   <Typography>{title}</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   <Grid container spacing={2}>
//                     <ViewGrid label1={label1} text1={text1} label2={label2} text2={text2}/>
//                   </Grid>
//                 </AccordionDetails>
//               </Accordion>
//             </div>
//     )
//   }
