import axios from "axios";

export const url = process.env.REACT_APP_CONNECTION_URL;

export const API = axios.create({ baseURL: url });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("login")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("login")).data.jwt
    }`;
  }
  return req;
});

export const signin = (data) => API.post(process.env.REACT_APP_LOGIN, data);
export const signout = () => API.put(process.env.REACT_APP_LOGOUT);

//year

export const newYear = (year) => API.post(process.env.REACT_APP_ADD_YEAR, year);
export const fetchYear = (val) =>
  API.post(process.env.REACT_APP_FIND_ALL_YEAR, val);
export const updateYear = (val1, val2) =>
  API.put(`${process.env.REACT_APP_FIND_UPDATE_YEAR}${val1}/newValue/${val2}`);
export const deleteYear = (year) =>
  API.delete(`${process.env.REACT_APP_FIND_DELETE_YEAR}${year}`);
export const promote = (val1, val2) =>
  API.put(`${process.env.REACT_APP_PROMOTE}${val1}/${val2}`);

// Busfees

export const fetchBusfees = (year) =>
  API.get(`${process.env.REACT_APP_FIND_ALL_BUS_FEES}${year}`);
export const createBusfees = (newfees) =>
  API.post(process.env.REACT_APP_ADD_BUS_FEES, newfees);
export const updateBusfees = (updatefees) =>
  API.put(process.env.REACT_APP_UPDATE_BUS_FEES, updatefees);
export const deleteBusfees = (val1, val2) =>
  API.delete(`${process.env.REACT_APP_DELETE_BUS_FEES}${val1}/${val2}`);
export const exportBusFees = (batchYear) =>
  API.get(`${process.env.REACT_APP_EXPORT_BUS_FEES}${batchYear}`);

// Class

export const fetchClass = () => API.get(process.env.REACT_APP_FIND_ALL_CLASS);
// export const createClass = (val) =>
//   API.post(process.env.REACT_APP_ADD_CLASS, val);
export const updateSection = (val1, val2) =>
  API.put(`${process.env.REACT_APP_ADD_CLASS}${val1}`, val2);

// Discount

export const fetchDiscount = () =>
  API.get(process.env.REACT_APP_FIND_ALL_DISCOUNT);
export const createDiscount = (val) =>
  API.post(process.env.REACT_APP_ADD_DISOCUNT, val);
export const updateDiscount = (val) =>
  API.put(process.env.REACT_APP_UPDATE_DISOCUNT, val);
export const deleteDiscount = (id) =>
  API.delete(`${process.env.REACT_APP_DELETE_DISOCUNT}${id}`);
export const exportDiscount = () =>
  API.get(process.env.REACT_APP_EXPORT_DISCOUNT);

// Academic Fees

export const fetchFees = (year) =>
  API.get(`${process.env.REACT_APP_FIND_ALL_TUITION_FEES}${year}`);
export const createFees = (val) =>
  API.post(process.env.REACT_APP_ADD_TUITION_FEES, val);
export const updateFees = (val1, val2, val) =>
  API.put(
    `${process.env.REACT_APP_UPDATE_TUITION_FEES}${val1}/batchYear/${val2}`,
    val
  );
export const deleteFees = (val1, val2) =>
  API.delete(
    `${process.env.REACT_APP_DELETE_TUITION_FEES}${val1}/batchYear/${val2}`
  );

export const exportTuitionFees = (batchYear) =>
  API.get(`${process.env.REACT_APP_EXPORT_TUITION_FEES}${batchYear}`);

// Student

export const fetchStudent = (val) =>
  API.post(process.env.REACT_APP_FIND_ALL_STUDENT, val);
export const createStudent = (val) =>
  API.post(process.env.REACT_APP_ADD_STUDENT, val);
export const updateStudent = (id, val) =>
  API.put(`${process.env.REACT_APP_UPDATE_STUDNET_DETAIL}${id}`, val);
export const updateAcademic = (val1, val2, val) =>
  API.put(
    `${process.env.REACT_APP_UPDATE_STUDNET_ACADEMIC_DETAIL}${val1}/${val2}`,
    val
  );
export const discontinueStudent = (val1, val2) =>
  API.put(
    `${process.env.REACT_APP_DISCONTINUE_STUDNET_ACADEMIC_DETAIL}${val1}/${val2}`
  );
export const deleteStudentAcademicDetails = (val1, val2) =>
  API.delete(
    `${process.env.REACT_APP_DELETE_STUDNET_ACADEMIC_DETAIL}${val1}/${val2}`
  );

//import
export const importStudent = (val) =>
  API.post(process.env.REACT_APP_IMPORT_STUDENT_DETAILS, val);

//export
export const exportStudent = (val) =>
  API.post(process.env.REACT_APP_EXPORT_STUDENT_DETAILS, val);

// Payment
export const payStudent = (val) =>
  API.post(process.env.REACT_APP_ADD_FEES_PAYMENT, val);
export const viewStudent = (id) =>
  API.get(`${process.env.REACT_APP_FIND_ALL_STUDNET_DETAIL}${id}`);
export const prevPayStudent = (val) =>
  API.post(process.env.REACT_APP_PREV_FEES_PAYMENT, val);
export const deletePaymentRecord = (id) =>
  API.delete(`${process.env.REACT_APP_DELETE_FEES_PAYMENT}${id}`);
export const findAllPaymentHistory = (val) =>
  API.post(process.env.REACT_APP_FIND_ALL_PAYMENT_HISTORY, val);
export const exportPaymentHistory = (val) =>
  API.post(process.env.REACT_APP_PAYMENT_HISTORY_EXPORT, val);
export const deletePreviousPayment = (id) =>
  API.delete(`${process.env.REACT_APP_DELETE_PREVIOUS_FEES_PAYMENT}${id}`);

//User

export const getRoles = () => API.get(process.env.REACT_APP_USER_GET_ROLES);
export const addUser = (val) => API.post(process.env.REACT_APP_USER_ADD, val);
export const findAllUser = (val) =>
  API.post(process.env.REACT_APP_USER_FIND_ALL, val);
export const getDirectors = () =>
  API.get(process.env.REACT_APP_USER_GET_DIRECTORS);

//Dashboard

export const fetchSummaryfees = (year) =>
  API.get(`${process.env.REACT_APP_FIND_SUMMARY_FEES}${year}`);
