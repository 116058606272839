import { FileUpload, GetApp } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { theme } from "../../Layouts/Themesetup/index";

import Radio from "@mui/material/Radio";
import { useRef } from "react";
import * as api from "../../api/api.js";
import useFetch from "../../api/useFetch";
import Modal from "../../Components/Modal/index";
import TabPanel from "../../Components/Tab";

const bloodGroup = [
  "O+ve",
  "O-ve",
  "A+ve",
  "A-ve",
  "B+ve",
  "B-ve",
  "AB+ve",
  "AB-ve",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddStudent = ({
  studentFilter,
  tableHeaderList,
  button,
  year,
  classNameList,
  discountList,
}) => {
  //useState
  const [busStateList, setBusState] = useFetch({ data: [] });
  const [userStateList, setUserState] = useFetch({ data: [] });
  const [studentPostData, setStudentPost] = useFetch(null);
  const [importFile, setImportFile, importSuccess, importError] =
    useFetch(null);
  const [exportFile, setExportFile] = useFetch(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [classIndex, setClassIndex] = useState(null);
  const [busRouteIndex, setBusRouteIndex] = useState(null);
  const [value, setValue] = useState(0);
  const [dob, setDob] = useState("");
  const [admissionDate, setAdmissionDate] = useState({ date: null });

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    content: "",
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  const postStudent = (studentDetails) => {
    setStudentPost({
      api: () => api.createStudent(studentDetails),
      callOnSuccess: handleClose,
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      admissionNo: "",
      mobileNumber: "",
      adharNumber: "",
      email: "",
      dob: null,
      studentCaste: "",
      studentReligion: "",
      gender: "",
      emisNo: "",
      bloodGroup: "",
      previousSchool: "",
      remark: "",
      batchYear: "",
      className: "",
      sectionName: "",
      groupId: "",
      busService: true,
      busUuid: "",
      busNumber: "",
      discountUuid: "",
      directorDiscount: 0,
      discountReason: "",
      fatherName: "",
      motherName: "",
      fatherMobileNo: "",
      motherMobileNo: "",
      homeMobileNo: "",
      fatherOccupation: "",
      motherOccupation: "",
      fatherCaste: "",
      motherCaste: "",
      fatherReligion: "",
      motherReligion: "",
      fatherAnnualIncome: 0,
      motherAnnualIncome: 0,
      fatherAadhaar: "",
      motherAadhaar: "",
      fatherQualification: "",
      motherQualification: "",
      fatherEmail: "",
      motherEmail: "",
      address1: "",
      address2: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      // admissionNo: Yup.string().required("Required"),
      // admissionDate: Yup.string().required("Required"),
      // dob: Yup.string().required("Required"),
      // gender: Yup.string().required("Required"),
      className: Yup.string().required("Required"),
      sectionName: Yup.string().required("Required"),
      fatherName: Yup.string().required("Required"),
      motherName: Yup.string().required("Required"),
      fatherMobileNo: Yup.string()
        .required("Required")
        .min(10, "Min 10 Numbers!"),
      motherMobileNo: Yup.string()
        .required("Required")
        .min(10, "Min 10 Numbers!"),
      // address1: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      postStudent({
        ...values,
        adharNumber: values.adharNumber === "" ? null : values.adharNumber,
        emisNo: values.emisNo === "" ? null : values.emisNo,
        admissionDate: admissionDate?.date?.format("YYYY-MM-DD"),
      });
    },
  });

  const getAllBusFees = () => {
    setBusState({
      api: () => api.fetchBusfees(year),
      res: (data) => ({
        data: data.data.busFeesList,
      }),
    });
  };

  const findAllUser = () => {
    setUserState({
      api: () =>
        api.findAllUser({
          userRole: null,
          size: 30,
          page: 0,
        }),
      res: (data) => ({ data: data.data.userList }),
    });
  };

  const fileImportRef = useRef(null);
  const fileExportRef = useRef(null);

  const handleImportClick = () => {
    fileImportRef.current.click();
  };

  const handleFileChange = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    setImportFile({
      api: () => api.importStudent(formData),
      res: (data) => ({ ...data }),
      showToast: false,
    });
    fileImportRef.current.value = "";
  };

  const handleExportFile = () => {
    const requestBody = { ...studentFilter, requestHeaders: tableHeaderList };
    setExportFile({
      api: () => api.exportStudent(requestBody),
      res: (data) => ({ fileUuid: data.data }),
    });
  };

  useEffect(() => {
    if (year) {
      formik.setFieldValue("batchYear", year);
      getAllBusFees();
      findAllUser();
    }
  }, [year]);

  useEffect(() => {
    if (importFile !== null || importError !== null) {
      const message = importSuccess
        ? importFile?.message
        : importError?.message;
      const data =
        typeof importFile?.data === "string"
          ? importFile?.data
          : importFile?.data;
      setModalState({
        open: true,
        title: message,
        content: importSuccess ? data : " ",
      });
    }
  }, [importFile, importSuccess, importError]);

  useEffect(() => {
    if (exportFile != null) {
      fileExportRef.current.click();
    }
  }, [exportFile]);

  return (
    <>
      <Modal
        open={modalState.open}
        setOpen={(val) => setModalState({ ...modalState, open: val })}
        title={modalState.title}
        content={modalState.content}
      ></Modal>
      <ThemeProvider theme={theme}>
        <a
          href={`https://svsschool.co.in/services/svs/api/student/exportFile/${exportFile?.fileUuid}`}
          ref={fileExportRef}
          target="_blank"
          rel="noreferrer"
        ></a>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Modal open={open} setOpen={setOpen} alertval={"hola"}></Modal>

          <Button variant="outlined" onClick={handleExportFile} color="primary">
            {<FileUpload />} Export
          </Button>
          <Button
            variant="outlined"
            onClick={handleImportClick}
            color="primary"
            sx={{ mx: 2 }}
          >
            <input
              ref={fileImportRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {<GetApp />}Import
          </Button>
          <Button
            onClick={handleOpen}
            variant="contained"
            size="medium"
            color="buttoncolor"
            sx={{ color: "#fff" }}
          >
            {button}
          </Button>
        </div>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 2, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <DialogTitle id="alert-dialog-title">Basic Information</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  spacing={2}
                  sx={{ overflow: "hidden", marginTop: "0.5px" }}
                >
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      id="name"
                      name="name"
                      label="Student Name*"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      name="admissionNo"
                      id="admissionNo"
                      label="Admission No"
                      value={formik.values.admissionNo}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.admissionNo && formik.errors.admissionNo ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.admissionNo}
                      </div>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="admissionDate"
                        label="Admission Date"
                        inputFormat="DD-MM-YYYY"
                        value={admissionDate.date}
                        onChange={(newValue) => {
                          setAdmissionDate({ date: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                margin: "0",
                                width: "100%",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      type="number"
                      id="mobileNumber"
                      name="mobileNumber"
                      label="Mobile Number"
                      value={formik.values.mobileNumber}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.mobileNumber}
                      </div>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      type="number"
                      id="adharNumber"
                      name="adharNumber"
                      label="Aadhaar Number"
                      value={formik.values.adharNumber}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.adharNumber && formik.errors.adharNumber ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.adharNumber}
                      </div>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      type="email"
                      id="email"
                      name="email"
                      label="Email Id"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.email && formik.errors.email ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.email}
                      </div>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="dob"
                        id="dob"
                        label="DOB"
                        inputFormat="YYYY-MM-DD"
                        value={dob}
                        onChange={(value) => {
                          setDob(value);

                          formik.setFieldValue(
                            "dob",
                            value.format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "&.MuiTextField-root": {
                                margin: "0",
                                width: "100%",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      id="studentCaste"
                      name="studentCaste"
                      label="Student Caste"
                      value={formik.values.studentCaste}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.studentCaste &&
                    formik.errors.studentCaste ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.studentCaste}
                      </div>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      id="studentReligion"
                      name="studentReligion"
                      label="Student Religion"
                      value={formik.values.studentReligion}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.studentReligion &&
                    formik.errors.studentReligion ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.studentReligion}
                      </div>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="gender"
                        id="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      label="Emis No"
                      name="emisNo"
                      id="emisNo"
                      value={formik.values.emisNo}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ margin: "0", width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Blood Group
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        label="bloodGroup"
                        name="bloodGroup"
                        id="bloodGroup"
                        value={formik.values.bloodGroup}
                        onChange={formik.handleChange}
                      >
                        {bloodGroup?.map((bg, key) => {
                          return (
                            <MenuItem key={key} value={bg}>
                              {bg}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      label="Previous School"
                      name="previousSchool"
                      id="previousSchool"
                      value={formik.values.previousSchool}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      label="Remark"
                      name="remark"
                      id="remark"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ margin: "0", width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Class*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="className"
                        label="Class*"
                        name="className"
                        value={formik.values.className}
                        onChange={formik.handleChange}
                      >
                        {classNameList?.data.map((cn, key) => {
                          return (
                            <MenuItem
                              key={key}
                              onClick={() => setClassIndex(key)}
                              value={cn.className}
                            >
                              {cn.className}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {formik.touched.class && formik.errors.class ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.class}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ margin: "0", width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Section*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="sectionName"
                        name="sectionName"
                        label="Section*"
                        value={formik.values.sectionName}
                        onChange={formik.handleChange}
                      >
                        {classIndex !== null
                          ? classNameList?.data[classIndex]?.sectionName
                              ?.split(",")
                              ?.map((sec) => {
                                return <MenuItem value={sec}>{sec}</MenuItem>;
                              })
                          : null}
                      </Select>
                    </FormControl>
                    {formik.touched.sectionName && formik.errors.sectionName ? (
                      <div style={{ marginLeft: "15px", color: "red" }}>
                        {formik.errors.sectionName}
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{
                        "&.MuiTextField-root": {
                          margin: "0",
                          width: "100%",
                        },
                      }}
                      id="groupId"
                      label="Group Id"
                      name="groupId"
                      value={formik.values.groupId}
                      onChange={formik.handleChange}
                      // onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      marginTop: 2,
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleTab}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Parent Details" {...a11yProps(0)} />
                      <Tab label="Contact Details" {...a11yProps(1)} />
                      <Tab label="Transport" {...a11yProps(2)} />
                      <Tab label="Discount" {...a11yProps(3)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        overflow: "hidden",
                        "& .css-19kzrtu": { padding: "0" },
                      }}
                    >
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherName"
                          id="fatherName"
                          label="Father Name*"
                          value={formik.values.fatherName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fatherName &&
                        formik.errors.fatherName ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.fatherName}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherOccupation"
                          id="fatherOccupation"
                          label="Father Occupation"
                          value={formik.values.fatherOccupation}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.fatherOccupation &&
                        formik.errors.fatherOccupation ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.fatherOccupation}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          type="number"
                          name="fatherMobileNo"
                          id="fatherMobileNo"
                          label="Father Mobile No*"
                          value={formik.values.fatherMobileNo}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fatherMobileNo &&
                        formik.errors.fatherMobileNo ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.fatherMobileNo}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherCaste"
                          id="fatherCaste"
                          label="Father Caste"
                          value={formik.values.fatherCaste}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.fatherCaste &&
                        formik.errors.fatherCaste ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.fatherCaste}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherReligion"
                          id="fatherReligion"
                          label="Father Religion"
                          value={formik.values.fatherReligion}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.fatherReligion &&
                        formik.errors.fatherReligion ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.fatherReligion}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherAnnualIncome"
                          id="fatherAnnualIncome"
                          label="Father Annual Income"
                          value={formik.values.fatherAnnualIncome}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherAadhaar"
                          id="fatherAadhaar"
                          label="Father Aadhaar"
                          value={formik.values.fatherAadhaar}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherQualification"
                          id="fatherQualification"
                          label="Father Qualification"
                          value={formik.values.fatherQualification}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="fatherEmail"
                          id="fatherEmail"
                          label="Father Email"
                          value={formik.values.fatherEmail}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherName"
                          id="motherName"
                          label="Mother Name*"
                          value={formik.values.motherName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.motherName &&
                        formik.errors.motherName ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.motherName}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherOccupation"
                          id="motherOccupation"
                          label="Mother Occupation"
                          value={formik.values.motherOccupation}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.motherOccupation &&
                        formik.errors.motherOccupation ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.motherOccupation}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          type="number"
                          name="motherMobileNo"
                          id="motherMobileNo"
                          label="Mother Mobile No*"
                          value={formik.values.motherMobileNo}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.motherMobileNo &&
                        formik.errors.motherMobileNo ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.motherMobileNo}
                          </div>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherCaste"
                          id="motherCaste"
                          label="Mother Caste"
                          value={formik.values.motherCaste}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.motherCaste &&
                        formik.errors.motherCaste ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.motherCaste}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherReligion"
                          id="motherReligion"
                          label="Mother Religion"
                          value={formik.values.motherReligion}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.motherReligion &&
                        formik.errors.motherReligion ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.motherReligion}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherAnnualIncome"
                          id="motherAnnualIncome"
                          label="Mother Annual Income"
                          value={formik.values.motherAnnualIncome}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherAadhaar"
                          id="motherAadhaar"
                          label="Mother Aadhaar"
                          value={formik.values.motherAadhaar}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherQualification"
                          id="motherQualification"
                          label="Mother Qualification"
                          value={formik.values.motherQualification}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="motherEmail"
                          id="motherEmail"
                          label="Mother Email"
                          value={formik.values.motherEmail}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        overflow: "hidden",
                        "& .css-19kzrtu": { padding: "0" },
                      }}
                    >
                      <Grid item xs={12} md={4}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="homeMobileNo"
                          id="homeMobileNo"
                          label="Home Mobile No"
                          value={formik.values.homeMobileNo}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.homeMobileNo &&
                        formik.errors.homeMobileNo ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.homeMobileNo}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="address1"
                          id="address1"
                          label="Address Line 1"
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.address1 && formik.errors.address1 ? (
                          <div style={{ marginLeft: "15px", color: "red" }}>
                            {formik.errors.address1}
                          </div>
                        ) : null} */}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="address2"
                          id="address2"
                          label="Address Line 2"
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Grid container spacing={2} sx={{ overflow: "hidden" }}>
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          sx={{ margin: "0", marginTop: 1, width: "100%" }}
                          control={
                            <Checkbox
                              name="busService"
                              id="busService"
                              defaultChecked
                              onChange={formik.handleChange}
                            />
                          }
                          label="Bus Service"
                        />
                      </Grid>
                      {formik.values.busService ? (
                        <>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ margin: "0", width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">
                                Bus Route
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="busUuid"
                                label="Bus Route"
                                name="busUuid"
                                value={formik.values.busUuid}
                                onChange={formik.handleChange}
                              >
                                {busStateList?.data.map((bf, key) => {
                                  return (
                                    <MenuItem
                                      key={key}
                                      onClick={() => setBusRouteIndex(key)}
                                      value={bf.uniqueId}
                                    >
                                      {bf.routeName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            {/* {formik.touched.busRoute &&
                            formik.errors.busRoute ? (
                              <div style={{ marginLeft: "15px", color: "red" }}>
                                {formik.errors.busRoute}
                              </div>
                            ) : null} */}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl sx={{ margin: "0", width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">
                                Bus No
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="busNumber"
                                label="Bus Number"
                                name="busNumber"
                                value={formik.values.busNumber}
                                onChange={formik.handleChange}
                              >
                                {busRouteIndex !== null
                                  ? busStateList?.data[
                                      busRouteIndex
                                    ]?.busNumber?.map((busNum) => {
                                      return (
                                        <MenuItem value={busNum}>
                                          {busNum}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                            </FormControl>
                            {/* {formik.touched.busNumber &&
                            formik.errors.busNumber ? (
                              <div style={{ marginLeft: "15px", color: "red" }}>
                                {formik.errors.busNumber}
                              </div>
                            ) : null} */}
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        overflow: "hidden",
                        "& .css-19kzrtu": { padding: "0" },
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <FormControl sx={{ margin: "0", width: "49%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Discount
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="discount"
                            label="Discount"
                            name="discount"
                            value={formik.values.discountUuid}
                            onChange={formik.handleChange}
                          >
                            {discountList?.data.map((discount, key) => {
                              return (
                                <MenuItem key={key} value={discount.uniqueId}>
                                  {discount.discountName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          type="number"
                          name="directorDiscount"
                          label="Director Provided Discount (%)"
                          value={formik.values.directorDiscount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl sx={{ margin: "0", width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Who Provide Director Discount
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="directorUuidWhoProvidedDiscount"
                            label="Who Provide Director Discount"
                            name="directorUuidWhoProvidedDiscount"
                            value={
                              formik.values.directorUuidWhoProvidedDiscount
                            }
                            onChange={formik.handleChange}
                          >
                            {userStateList?.data.map((user, key) => {
                              return (
                                <MenuItem key={key} value={user.userUuid}>
                                  {user.userName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          sx={{
                            "&.MuiTextField-root": {
                              margin: "0",
                              width: "100%",
                            },
                          }}
                          name="discountReason"
                          label="Discount Reason"
                          value={formik.values.discountReason}
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ marginBottom: "10px", marginRight: "20px" }}>
              <Button onClick={handleClose}>Close</Button>
              <Button
                type="submit"
                disabled={
                  !formik.values.name ||
                  !formik.values.className ||
                  !formik.values.sectionName ||
                  !formik.values.fatherName ||
                  !formik.values.motherName ||
                  !formik.values.fatherMobileNo ||
                  !formik.values.motherMobileNo
                    ? "disabled"
                    : ""
                }
                size="large"
                variant="contained"
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        {/* <Dialog
        open={impopen}
        onClose={importClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          component="form"
          sx={{
            padding: 5,
            "& .MuiTextField-root": { m: 2, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="file" {...register("file")} />

          <Button variant="contained" component="label">
            Submit
            <input hidden type="submit" />
          </Button>
        </Box>
      </Dialog> */}
      </ThemeProvider>
    </>
  );
};

export default AddStudent;
