import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { theme } from "../../Layouts/Themesetup/index";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  flexGrow: 1,
};

const Promote = ({ button, promoteFun, yearData }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
    },
    onSubmit: (values) => {
      promoteFun(values);
      handleClose();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleOpen}
          variant="contained"
          size="medium"
          color="buttoncolor"
          sx={{ color: "#fff" }}
        >
          {button}
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-from-batch">
                  From Batch
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-from-batch"
                  id="from"
                  name="from"
                  label="From Batch"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                >
                  {yearData?.data?.map((ye, key) => {
                    return (
                      <MenuItem key={key} value={ye.batchYear}>
                        {ye.batchYear}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-to-batch">
                  To Batch
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-to-batch"
                  id="to"
                  name="to"
                  label="To Batch"
                  value={formik.values.to}
                  onChange={formik.handleChange}
                >
                  {yearData?.data?.map((ye, key) => {
                    return (
                      <MenuItem key={key} value={ye.batchYear}>
                        {ye.batchYear}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} container justifyContent="center">
              <Button
                variant="contained"
                disabled={
                  !formik.values.from || (!formik.values.to ? "disabled" : "")
                }
                color="primary"
                size="large"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Promote;
